import PropTypes from 'prop-types'
import React from 'react'
import {
	StatusIndicator,
	StatusWonIcon,
	StatusLostIcon,
	StatusExpiredIcon,
	StatusPendingIcon,
	StatusDraftIcon,
} from 'maxboapp-components'
import { OFFER } from '@src/constants/offer'

const icons = {
	[OFFER.STATUS_CODE.WON]: <StatusWonIcon />,
	[OFFER.STATUS_CODE.LOST]: <StatusLostIcon />,
	[OFFER.STATUS_CODE.PENDING]: <StatusPendingIcon />,
	[OFFER.STATUS_CODE.DRAFT]: <StatusDraftIcon />,
	[OFFER.STATUS_CODE.EXPIRED]: <StatusExpiredIcon />,
}

export const OfferStatus = ({ code, tooltip = '' }) => {
	const icon = icons[code]
	return <StatusIndicator icon={icon} tooltip={tooltip} />
}

OfferStatus.propTypes = {
	code: PropTypes.oneOf(Object.keys(icons)).isRequired,
	tooltip: PropTypes.string,
}
