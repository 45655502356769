import * as om from '@src/overmind'
import { Container, ModalProvider, ToastList } from 'maxboapp-components'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Header } from '../header'
import { OfferTabs } from './OfferTabs'
import { useTranslator } from '@src/hooks/useTranslations'

export const Offer = ({ uri, location, offerId }) => {
	const t = useTranslator('erpapp.portal.offer.errors').s

	const actions = om.useActions()
	const toastMessages = om.useState().toast.messages

	useEffect(() => {
		actions.offers.onNavigate(offerId)
	}, [actions.offers, offerId])

	return (
		<>
			<Header full uri={uri} />
			<ModalProvider>
				<Container size='full'>
					<OfferTabs location={location} />
				</Container>
				<ToastList
					messages={toastMessages.map(({ message, extraData, type }, index) => ({
						message:
							t(message, null, {
								noLog: true,
								validateKey: true,
								fallbackValue: message,
							}) + (extraData ? ': ' + extraData : ''),
						type,
						onClose: () => actions.toast.removeMessage(index),
					}))}
				/>
			</ModalProvider>
		</>
	)
}

Offer.propTypes = {
	uri: PropTypes.string.isRequired,
	offerId: PropTypes.string,
	location: PropTypes.shape({}),
}
