import { PATH } from '@src/constants/path'
import * as om from '@src/overmind'
import { Link, navigate } from 'gatsby'
import { Button, Dropdown, Icon } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import React, { useRef, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import './Header.scss'

const bem = new BEMHelper('header')

export function HeaderLoggedInSection() {
	const t = useTranslator()
	const tH = t.namespaced(() => 'erpapp.portal.header')
	const actions = om.useActions()
	const state = om.useState()
	const [menuExpanded, setMenuExpanded] = useState(false)
	const menuWrapper = useRef(null)
	const menuTrigger = useRef(null)

	const toggleMenu = ({ force }) => {
		const expanded = force === false || force === true ? force : !menuExpanded
		setMenuExpanded(expanded)
		if (expanded) {
			setTimeout(() => {
				menuWrapper?.current?.focus()
			}, 100)
		} else {
			menuTrigger?.current?.focus()
		}
		document.body.classList.toggle('block-scrolling')
	}

	const links = [{ to: PATH.PORTAL.OFFER, text: tH.s('offer-link.text') }]
	const { username } = state.auth

	return (
		<>
			<div {...bem('nav-menu', { expanded: menuExpanded })} ref={menuWrapper} tabIndex={-1}>
				{menuExpanded && (
					<button
						type='button'
						onClick={toggleMenu}
						{...bem('burger', 'expanded')}
						aria-label='Meny'
					>
						<Icon icon='closeLarge' {...bem('menu-icon')} />
					</button>
				)}
				<ul {...bem('main-menu')}>
					{links.map(({ to, text }) => (
						<li {...bem('menu-item')} key={to}>
							<Link to={to} {...bem('link')}>
								{text}
							</Link>
						</li>
					))}
					<li {...bem('menu-item')}>
						<Dropdown
							trigger={username}
							icon
							small
							left
							placement={'bottom-start'}
							options={[
								{
									label: username,
									selected: true,
									onClick: () => navigate(PATH.PORTAL.PROFILE),
								},
								{
									label: t.s('erpapp.logout'),
									onClick: actions.auth.logout,
								},
							]}
						/>
					</li>
				</ul>
			</div>
			<div {...bem('nav-actions')}>
				<Button small onClick={actions.offers.createEmpty}>
					{tH.s('new-offer.text')}
				</Button>
				<Button onClick={toggleMenu} {...bem('burger')} link>
					<Icon icon='menu' {...bem('menu-icon')} />
				</Button>
			</div>
		</>
	)
}
HeaderLoggedInSection.propTypes = {}
