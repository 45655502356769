import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { Container } from 'maxboapp-components'
import { Header } from '@src/components/portal/header'
import { PATH } from '@src/constants/path'
import useTranslations from '@src/hooks/useTranslations'

export function Dashboard({ uri }) {
	const t = useTranslations()
	return (
		<>
			<Helmet>
				<title>Salgsportalen</title>
			</Helmet>
			<Header full uri={uri} />
			<Container>
				<Link to={PATH.PORTAL.OFFER}>{t('erpapp.offer.go-to-offer')}</Link>
			</Container>
		</>
	)
}
Dashboard.propTypes = {
	uri: PropTypes.string.isRequired,
}
