import React from 'react'
import PropTypes from 'prop-types'
import { OfferSummary } from '@src/components/portal/offer/summary'

export const OfferPreview = ({ offerId }) => {
	return <OfferSummary offerId={offerId} />
}

OfferPreview.propTypes = {
	offerId: PropTypes.string.isRequired,
}
