import * as om from '@src/overmind'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { PATH } from '@src/constants/path'

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const state = om.useState()
	const actions = om.useActions()
	const { location } = rest
	const showLogin = !state.auth.isLoggedIn && location.pathname !== PATH.PORTAL.LOGIN

	useEffect(() => {
		if (showLogin) {
			actions.stateTree.clearAndReload()
		}
	}, [showLogin, location.pathname, actions])

	return showLogin ? null : <Component {...rest} />
}

PrivateRoute.propTypes = {
	component: PropTypes.elementType,
	location: PropTypes.shape({ pathname: PropTypes.string }),
}
