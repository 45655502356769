import React from 'react'
import BEMHelper from 'react-bem-helper'
import { OfferStatus } from '../OfferStatus'
import { useTranslator } from '@src/hooks/useTranslations'
import { localizer, LOCALE_CODES } from '@src/utils/locale'

const { convert, format } = localizer(LOCALE_CODES.NB).date
const bem = new BEMHelper('status-overview')

export const StatusDetails = ({ offer }) => {
	const t = useTranslator('erpapp.portal.offer.published')
	const tS = t.namespaced((ns) => `${ns}.change-status`)
	const { statusCode, orderDate } = offer

	return (
		<p {...bem('icon-wrapper')}>
			<OfferStatus code={statusCode} />
			{orderDate
				? t.s(`order-placed-at.text`, [format.dateTimeWithSeconds(convert(orderDate))])
				: tS.s(`${statusCode}-at.text`, [format.dateTimeWithSeconds(convert(offer.modifiedAt))])}
		</p>
	)
}
