export const createBaseUnitFactor = (baseUnit, validUnits) => (unit) => {
	const fromBase = validUnits.find(
		({ fromUnit, toUnit }) => fromUnit === baseUnit && toUnit === unit,
	)
	const toBase = validUnits.find(({ fromUnit, toUnit }) => fromUnit === unit && toUnit === baseUnit)

	return Boolean(fromBase) ? fromBase.factor : Boolean(toBase) ? 1 / toBase.factor : 1
}

/** Converts a number of base units to given unit */
export const createFromBaseDeriver = (changeFactor) => (quantity) => {
	return Math.floor(quantity / changeFactor)
}

/** Converts a number of given units to base unit */
export const createToBaseDeriver = (changeFactor) => (quantity) => {
	return Math.ceil(quantity * changeFactor)
}
