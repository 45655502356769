import './UnitConversionCell.scss'

import React from 'react'
import { ButtonBase, PlainTableCell } from 'maxboapp-components'
import BEMHelper from 'react-bem-helper'
const bem = new BEMHelper('unit-conversion-cell')

export const UnitConversionCell = ({ col, data: offerItem, rowId, ...props }) => {
	const { unit, productUnitData } = offerItem ?? {}
	if (!unit) {
		return null
	}

	const { conversions = [], baseUnit } = productUnitData ? JSON.parse(productUnitData) : {}

	const hasOtherUnits =
		conversions.filter(({ fromUnit, toUnit }) => fromUnit !== baseUnit || toUnit !== baseUnit)
			.length > 0

	return (
		<PlainTableCell
			{...props}
			col={col}
			rowId={rowId}
			data={
				hasOtherUnits ? (
					<ButtonBase onClick={(e) => col.onClick(e, rowId)} {...bem('button')}>
						{unit}
					</ButtonBase>
				) : (
					unit
				)
			}
		/>
	)
}
