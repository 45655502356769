import React from 'react'
import { OFFER } from '@src/constants/offer'
import { LOCALE_CODES, localizer } from '@src/utils/locale'
import { translateStatus } from '@src/utils/offer'
import { OfferStatus } from '@src/components/portal/offer/OfferStatus'
import { formatPrice } from 'maxboapp-components'

const { convert } = localizer(LOCALE_CODES.NB).date
const formatExpiryDate = (date) => (date ? convert(date).format('DD.MM.YYYY') : '')
const formatErpOrderRef = (erpOrderRef) => (erpOrderRef ? `#${erpOrderRef}` : null)

const joinElementsWithPrice = (translate, elements, price) =>
	[...elements, formatPrice(price, translate('erpapp.default-currency'))]
		.filter(Boolean)
		.join(' | ')

export const getOfferSubtitle = (translate, offer) =>
	joinElementsWithPrice(
		translate,
		[offer.companyName, formatExpiryDate(offer.expiryDate), formatErpOrderRef(offer.erpOrderRef)],
		offer.priceTotalExcludingTax,
	)

export const getCompanyOfferSubtitle = (translate, offer) =>
	joinElementsWithPrice(
		translate,
		[formatExpiryDate(offer.expiryDate), formatErpOrderRef(offer.erpOrderRef)],
		offer.priceTotalExcludingTax,
	)

export const mapToOfferOption = (translate, selectedId, onClick) => (getSubtitle) => (offer) => {
	const { id, title, orderDate } = offer
	const code = offer.statusCode ?? OFFER.STATUS_CODE.DRAFT

	const name = translateStatus(orderDate ? 'order-placed' : code, translate)
	const icon = <OfferStatus code={code} />

	// TODO remove the need of this status object, we need the code and the icon
	// is required for the SelectGroup component
	const status = { code, name, tooltip: name, icon }

	return {
		id,
		onClick: () => onClick?.(id),
		title,
		subtitle: getSubtitle(translate, offer),
		suffix: status.name,
		selected: id === selectedId,
		status,
	}
}
