import React from 'react'
import {
	Accordion,
	Container,
	DownloadList,
	Section,
	Grid,
	Editorial,
	InfoBlock,
	TableLineItems,
	TableFooter,
	CurrencyTableCell,
	PlainTableCell,
	QuantityTableCell,
	ProductAdvancedTableCell,
} from 'maxboapp-components'
import BEMHelper from 'react-bem-helper'
import { localizer, LOCALE_CODES } from '@src/utils/locale'
import * as om from '@src/overmind'
import { useTranslator } from '@src/hooks/useTranslations'
import { getSortOrder } from '@src/utils/offer'
import { getDownloadUrl, getFileType, getPublicDownloadUrl } from '@src/utils/offer/attachment'
import './SummaryDetails.scss'

const bem = new BEMHelper('summary-details')
const { convert, format } = localizer(LOCALE_CODES.NB).date

const createOfferInfo = (t, offer) => [
	{
		id: 1,
		label: t.s('offer-number.label'),
		value: offer.id,
		small: false,
	},
	{
		id: 2,
		label: t.s('offer-deadline.label'),
		value: offer.expiryDate ? format.year(convert(offer.expiryDate)) : '-',
	},
	{
		id: 3,
		label: t.s('created-date.label'),
		value: format.year(convert(offer.createdAt)),
	},
]

const createCustomerInfo = (t, offer) => {
	return [
		{
			id: 0,
			label: t.s('customer-number.label'),
			value: offer.customerErpNo,
		},
		{
			id: 1,
			label: t.s('billing.label'),
			value: offer.project?.address,
		},
		{
			id: 3,
			label: t.s('project.label'),
			value: offer.project?.name,
		},
		{
			id: 4,
			label: t.s('reference.label'),
			value: `${offer.customerRefName}, ${offer.customerRefPhone}`,
		},
	]
}

export const SummaryDetails = () => {
	const state = om.useState()
	const t = useTranslator()
	const tT = t.namespaced(() => 'erpapp.portal.summary.table')
	const tOffer = t.namespaced(() => 'erpapp.portal.summary.offer-info')
	const tCust = t.namespaced(() => 'erpapp.portal.summary.customer-info')
	const tAttachment = t.namespaced(() => 'erpapp.portal.offer.attachment')

	const offer = state.offers.selectedOffer
	const magentoToken = state.auth.token

	if (!offer) {
		return null
	}

	const offerGroupIds = (state.offerGroups.byOffer[offer.id] ?? []).filter(
		(id) => state.offerItems.byGroup[id]?.length,
	)

	const offerGroups = getSortOrder(offer.groupOrder, offerGroupIds).map(
		(id) => state.offerGroups.local.db[id],
	)

	const itemOrderByGroup = offerGroups.reduce(
		(o, { id, itemOrder }) => ({
			...o,
			[id]: getSortOrder(itemOrder, state.offerItems.byGroup[id]),
		}),
		{},
	)

	const offerInfo = createOfferInfo(tOffer, offer)
	const customerInfo = createCustomerInfo(tCust, offer)

	const columns = [
		{
			id: 'product',
			label: tT.s('product.label'),
			component: ProductAdvancedTableCell,
			style: {
				width: '360px',
			},
			getData: ({ rowId }) => {
				const { productName: name, comment } = om.useState().offerItems.local.db[rowId] ?? {}
				return { name, comment }
			},
		},
		{
			id: 'sku',
			label: tT.s('sku.label'),
			component: PlainTableCell,
			style: {
				width: '100px',
			},
			getData: ({ rowId }) => om.useState().offerItems.local.db[rowId].sku,
		},
		{
			id: 'erpOfferPriceAtPublish',
			label: tT.s('original-price.label'),
			component: CurrencyTableCell,
			style: {
				width: '100px',
				textAlign: 'right',
			},
			getData: ({ rowId }) => {
				const item = om.useState().offerItems.local.db[rowId]
				return item.erpOfferPriceAtPublish ?? item.currentErpOfferPrice
			},
		},
		{
			id: 'offeredPrice',
			label: tT.s('offer-price.label'),
			component: CurrencyTableCell,
			style: {
				width: '100px',
				textAlign: 'right',
			},
			getData: ({ rowId }) => om.useState().offerItems.local.db[rowId].offeredPrice,
		},
		{
			id: 'qty',
			label: tT.s('quantity.label'),
			component: QuantityTableCell,
			style: {
				width: '100px',
				textAlign: 'right',
			},
			getData: ({ rowId }) => {
				const { qty, unit } = om.useState().offerItems.local.db[rowId]
				return qty && unit ? `${qty} ${unit}` : qty
			},
		},
		{
			id: 'total',
			label: tT.s('total-item.label'),
			component: CurrencyTableCell,
			style: {
				width: '100px',
				textAlign: 'right',
			},
			getData: ({ rowId }) => om.useState().offerItems.local.db[rowId].total || '',
		},
	]

	const footerColumns = [
		{
			id: 'mva',
			label: tT.s('total-tax.label'),
			getData: () => Number.parseFloat(offer.taxTotal),
			component: CurrencyTableCell,
		},
		{
			id: 'total',
			label: tT.s('total-excl-tax.label'),
			getData: () => Number.parseFloat(offer.priceTotalExcludingTax),
			component: CurrencyTableCell,
		},
	]

	const attachmentIds = state.attachments.byOffer?.[offer.id] ?? []
	const attachmentOrder = getSortOrder(offer.attachmentOrder, attachmentIds)
	const attachments = attachmentOrder
		.map((id) => state.attachments.local.db[id])
		.filter(Boolean)
		.map((file) => ({
			title: file.name,
			description: tAttachment.s('description.text', [
				getFileType(file.name).toUpperCase(),
				file.size,
			]),
			to: offer.publicId
				? getPublicDownloadUrl(offer.publicId, file.id)
				: getDownloadUrl(offer.id, file.id, magentoToken),
		}))

	return (
		<>
			<Container {...bem('')}>
				<Grid grid='2-1' padding>
					<Section>
						<h1>{offer.title}</h1>
						{offer.description && (
							<Editorial linebreak spacing='xlarge'>
								{offer.description}
							</Editorial>
						)}
					</Section>
					<Section box spacing='xxlarge'>
						{offerInfo.map(({ id, label, value, small }) => (
							<InfoBlock key={id} label={label} small={small ?? true}>
								{value}
							</InfoBlock>
						))}
						<br />

						<Accordion title={offer.customerRefName}>
							{customerInfo
								.filter(({ value }) => value)
								.map(({ id, label, value }) => (
									<InfoBlock key={id} label={label} small>
										{value}
									</InfoBlock>
								))}
						</Accordion>
					</Section>
				</Grid>

				{offerGroups.map(({ id, name }) => (
					<Section spacing='xlarge' key={id}>
						<h2>{name}</h2>
						<TableLineItems
							columns={columns}
							items={getSortOrder(itemOrderByGroup[id], state.offerItems.byGroup[id])}
						/>
					</Section>
				))}

				<TableFooter columns={footerColumns} />

				{attachments.length > 0 && (
					<Section spacing='xxlarge'>
						<h2>{tAttachment.s('')}</h2>
						<DownloadList float items={attachments} />
					</Section>
				)}
				<br />
				<br />
			</Container>
		</>
	)
}
