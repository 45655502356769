"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileType = exports.getPublicDownloadUrl = exports.getDownloadUrl = void 0;
var HOST = process.env.GATSBY_PIMCORE_ERPAPP_BASE_URL;
var VERSION = process.env.GATSBY_PIMCORE_ERPAPP_REST_VERSION;
var BASE_URL = HOST + "/erpapp/" + VERSION + "/salesportal/offers";
var idUrl = function idUrl(id) {
    return BASE_URL + "/" + id + "/attachments";
};
var getDownloadUrl = function getDownloadUrl(offerId, attachmentId, magentoToken) {
    return idUrl(offerId) + "/" + attachmentId + "/download?magentoToken=" + magentoToken;
};
exports.getDownloadUrl = getDownloadUrl;
var getPublicDownloadUrl = function getPublicDownloadUrl(publicId, attachmentId) {
    return idUrl(publicId) + "/" + attachmentId + "/download-by-public-id";
};
exports.getPublicDownloadUrl = getPublicDownloadUrl;
var getFileType = function getFileType(fileName) {
    var _fileName$split$, _fileName$split;
    return (_fileName$split$ = (_fileName$split = fileName.split('.')) === null || _fileName$split === void 0 ? void 0 : _fileName$split[1]) !== null && _fileName$split$ !== void 0 ? _fileName$split$ : '';
};
exports.getFileType = getFileType;
