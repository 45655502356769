import { Logo } from '@src/components/lib'
import { PATH } from '@src/constants/path'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import './SummaryHeader.scss'

const bem = new BEMHelper('summary-header')

export const SummaryHeader = ({ children }) => {
	return (
		<nav {...bem('', { full: true })}>
			<div {...bem('content')}>
				<Link to={PATH.PORTAL.DASHBOARD} {...bem('branding')}>
					<Logo />
				</Link>
				{children}
			</div>
		</nav>
	)
}

SummaryHeader.propTypes = {
	children: PropTypes.node,
}
