import React from 'react'
import isEqual from 'lodash/isEqual'
import { TableLineItems } from 'maxboapp-components'

export const MemoizedTableLineItems = React.memo(
	({ ...props }) => <TableLineItems {...props} />,
	(prev, next) => {
		return isEqual(
			{
				droppableId: prev.droppableId,
				droppableType: prev.droppableType,
				items: prev.items,
				reorder: prev.reorder,
				dndStates: prev.dndStates,
				memoize: prev.memoize,
			},
			{
				droppableId: next.droppableId,
				droppableType: next.droppableType,
				items: next.items,
				reorder: next.reorder,
				dndStates: next.dndStates,
				memoize: next.memoize,
			},
		)
	},
)
