import { useTranslator } from '@src/hooks/useTranslations'
import { FormRow, Preloader, Section } from 'maxboapp-components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import BEMHelper from 'react-bem-helper'
import './StatusOverview.scss'
import HandshakeProff from 'maxboapp-components/assets/illustrations/handshake-proff.svg'
import { StatusOrderPlaced } from './StatusOrderPlaced'
import { StatusOrderNotPlaced } from './StatusOrderNotPlaced'
import { StatusDetails } from './StatusDetails'

const bem = new BEMHelper('status-overview')

export const StatusOverview = ({ offer }) => {
	const [placingOrder, setPlacingOrder] = useState(false)
	const t = useTranslator('erpapp.portal.offer.published')
	const tS = t.namespaced((ns) => `${ns}.change-status`)

	const { erpOrderRef } = offer

	return (
		<>
			<Section box spacing='xlarge' image={HandshakeProff}>
				<FormRow marg>
					<h2>{tS.s('title')}</h2>
					<StatusDetails offer={offer} />
					{placingOrder ? (
						<div {...bem('preloader-wrapper')}>
							<Preloader debounce large />
						</div>
					) : (
						<>
							{erpOrderRef ? (
								<StatusOrderPlaced erpOrderRef={erpOrderRef} />
							) : (
								<StatusOrderNotPlaced offer={offer} setPlacingOrder={setPlacingOrder} />
							)}
						</>
					)}
				</FormRow>
			</Section>
		</>
	)
}

StatusOverview.propTypes = {
	offer: PropTypes.shape({ statusCode: PropTypes.string, modifiedAt: PropTypes.string }).isRequired,
}
