import { Logo } from '@src/components/lib'
import { PATH } from '@src/constants/path'
import * as om from '@src/overmind'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import './Header.scss'
import { HeaderLoggedInSection } from './HeaderLoggedInSection'

const bem = new BEMHelper('header')

export function Header({ uri, full }) {
	const frontPage = uri === PATH.PORTAL.DASHBOARD

	return (
		<nav {...bem('', { full })}>
			<div {...bem('content')}>
				<Link to={PATH.PORTAL.DASHBOARD} {...bem('branding')}>
					{frontPage ? (
						<h1 {...bem('title')}>
							<Logo />
						</h1>
					) : (
						<Logo />
					)}
				</Link>

				{om.useState().auth.isLoggedIn && <HeaderLoggedInSection />}
			</div>
		</nav>
	)
}
Header.propTypes = {
	uri: PropTypes.string.isRequired,
	full: PropTypes.bool,
}
