import useTranslations from '@src/hooks/useTranslations'
import PropTypes from 'prop-types'
import React from 'react'
import { SelectSearch, Pagination } from 'maxboapp-components'
import * as om from '@src/overmind'
import BEMHelper from 'react-bem-helper'
import './AssortmentSearch.scss'

const bem = new BEMHelper('assortment-search')

const AssortmentSearch = ({ onItemClick }) => {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const { productOptions, productSearch } = state.products
	const { query, isQueryValid, isSearching, paging } = productSearch

	return (
		<SelectSearch
			closeOnClick
			label={t('erpapp.product.search.label')}
			searchQuery={query}
			filterable={false}
			setSearchQuery={actions.products.setQuery}
			placeholder={t('erpapp.product.search.placeholder')}
			options={isQueryValid && productOptions}
			isSearching={isSearching}
			searchingMessage={t('erpapp.product.search.searching')}
			notFoundMessage={t('erpapp.search.no-items', [query])}
			onItemClick={(e) => {
				onItemClick(e)
				actions.products.setQuery('')
			}}
			showOptionsLoading
			pagination={
				!isSearching &&
				productOptions.length > 0 && (
					<div {...bem('pagination')}>
						<Pagination
							nextDisabled={!Number.isFinite(paging.nextSkip)}
							onNext={() => actions.products.nextPage()}
							previousDisabled={!Number.isFinite(paging.previousSkip)}
							onPrevious={() => actions.products.previousPage()}
						>
							{t('erpapp.product.search.pagination.page.text', [paging.currentPage])}
						</Pagination>
					</div>
				)
			}
		/>
	)
}
AssortmentSearch.propTypes = {
	onItemClick: PropTypes.func,
}

export default AssortmentSearch
