import React from 'react'
import { Button } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'

export const Order = ({ disabled, setPlacingOrder }) => {
	const t = useTranslator('erpapp.portal.offer.published')
	const actions = om.useActions()

	return (
		<>
			<p>
				<Button
					disabled={disabled}
					onClick={async () => {
						setPlacingOrder(true)
						try {
							await actions.offers.placeOrder()
						} catch (error) {}
						setPlacingOrder(false)
					}}
				>
					{t.s('place-order.text')}
				</Button>
			</p>
			{disabled && <p>{t.s('store-not-supported')}</p>}
		</>
	)
}
