import PropTypes from 'prop-types'
import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Router } from '@reach/router'
import NotFoundPage from '@src/pages/404'
import { PrivateRoute } from '@src/components/auth/PrivateRoute'
import { Dashboard } from '@src/components/portal/Dashboard'
import { LoginPage } from '@src/components/portal/login/LoginPage'
import { PublicOffer } from '@src/components/portal/PublicOffer'
import { Offer } from '@src/components/portal/offer'
import { OfferPreview } from '@src/components/portal/offer/OfferPreview'
import { PATH } from '@src/constants/path'
import Layout from '@src/layouts/layout'

const Portal = () => (
	<Layout>
		<Router>
			<PrivateRoute path={PATH.PORTAL.DASHBOARD} component={Dashboard} />
			<PrivateRoute path={PATH.PORTAL.OFFER} component={Offer} />
			<PrivateRoute path={`${PATH.PORTAL.OFFER}/:offerId/preview`} component={OfferPreview} />
			<PrivateRoute path={`${PATH.PORTAL.OFFER}/:offerId`} component={Offer} />
			<PublicOffer path={`${PATH.PORTAL.MY_OFFER}/:publicOfferId`} component={PublicOffer} />
			<LoginPage path={PATH.PORTAL.LOGIN} />
			<NotFoundPage default />
		</Router>
	</Layout>
)

export default Portal

Portal.propTypes = {
	location: PropTypes.shape({ pathname: PropTypes.string }),
}
