import './RFQModal.scss'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { Button, FormInput, FormRow, InfoBlock, Modal2 as Modal } from 'maxboapp-components'
import useFormInput from 'maxboapp-components/hooks/useFormInput'
import { isNotEmpty } from 'maxboapp-components/utils/validators'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import { RFQItemsTable } from './RFQItemsTable'

const bem = new BEMHelper('rfq-modal')

// NOTE: 'RFQ' is an acronym for 'Request For Quotation'.
export const RFQModal = ({ itemIds, onClose }) => {
	const t = useTranslator()
	const transRFQ = t.namespaced(() => 'erpapp.portal.offer-item.rfq.modal').s

	const state = om.useState()
	const actions = om.useActions()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const offerItems = itemIds.reduce(
		(acc, id) => ({
			...acc,
			[id]: { ...state.offerItems.local.client?.[id], ...state.offerItems.local.db?.[id] },
		}),
		{},
	)
	const { supplierOfferNumber } = useFormInput({
		name: 'supplierOfferNumber',
		validators: [isNotEmpty],
		options: { translationPrefix: 'erpapp' },
	})

	useEffect(() => {
		if (itemIds.length === 1) {
			// NOTE: Setting initial value for supplier offer number when having only one item
			const initialValue = offerItems?.[itemIds?.[0]]?.supplierOfferNumber ?? ''
			supplierOfferNumber.updateValue(initialValue)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemIds])

	const reset = () => {
		setIsSubmitting(false)
		onClose()
		supplierOfferNumber.updateValue('')
	}

	const onSupplierNumberChange = (e) => {
		supplierOfferNumber.onChange(e)
		Object.keys(offerItems).forEach((id) =>
			actions.offerItems.setTempSupplierOfferNumber({
				id,
				supplierOfferNumber: e.target.value,
			}),
		)
	}

	const onSubmit = async () => {
		setIsSubmitting(true)

		if (!supplierOfferNumber.isValid) {
			return
		}
		Object.keys(offerItems).forEach((id) => actions.offerItems.commitNewCostPrice({ id }))
		await actions.offerItems.commitChanges()
		reset()
	}

	return (
		itemIds.length > 0 && (
			<Modal title={transRFQ('title')} medium handleClose={reset}>
				<br />

				<FormRow marg>
					{/* <Grid grid='3'> */}
					<InfoBlock label={t.s('erpapp.portal.offer.product.brand')}>
						{offerItems?.[Object.keys(offerItems)?.[0]]?.productBrand ?? ''}
					</InfoBlock>
					{/* TODO: No information available at the moment
					<InfoBlock label='E-post'>
						<a href='mailto:contact@natre.no'>contact@natre.no</a>
					</InfoBlock>
					<InfoBlock label='Telefon'>22 45 65 84</InfoBlock> */}
					{/* </Grid> */}
				</FormRow>

				<FormRow marg>
					<h3>{transRFQ('header')}</h3>
					<FormInput
						name={supplierOfferNumber.name}
						value={supplierOfferNumber.value}
						label={transRFQ('supplier-offer-number.label')}
						onChange={onSupplierNumberChange}
						errors={supplierOfferNumber.errors.map((err) => t.s(err))}
						forceShowErrors={isSubmitting}
						tight
					/>

					<RFQItemsTable offerItems={offerItems} />
					{/* <Button small>Legg til alle varelinjer fra Natre</Button> */}
				</FormRow>

				<FormRow>
					<p>{transRFQ('info.text')}</p>
				</FormRow>

				<FormRow>
					<Button
						primary
						large
						onClick={onSubmit}
						disabled={!supplierOfferNumber.isValid}
						className={bem('button').className}
					>
						{transRFQ('submit-button.text')}
					</Button>
				</FormRow>
			</Modal>
		)
	)
}

RFQModal.propTypes = {
	itemIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
	onClose: PropTypes.func.isRequired,
}
