export const aggregateSearch = (customers, offers) => {
	const enrichedCustomers = {}
	Object.keys(customers)
		.map((key) => {
			const c = { ...customers[key] }
			c.uniqueId = key
			return c
		})
		.forEach((c) => {
			enrichedCustomers[c.uniqueId] = c
		})
	const enrichedOffers = {}
	Object.keys(offers)
		.map((key) => {
			const c = { ...offers[key] }
			c.uniqueId = key
			return c
		})
		.forEach((o) => {
			enrichedOffers[o.uniqueId] = o
		})

	return Object.values({ ...enrichedCustomers, ...enrichedOffers })
}
