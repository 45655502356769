import './Logo.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('logo')

const Logo = ({ light, className }) => {
	return (
		<svg viewBox='0 0 101.3 30.8' {...bem('', { light }, className)}>
			<title>Maxbo Proff Logo</title>
			<g id='Lager_2'>
				<g id='Layer_1_1_'>
					<path
						d='M90.1,0C83,0,78.8,4.3,78.8,8.5c0,0.5,0.1,1,0.2,1.4c-0.8-1.1-2.1-1.8-3.4-1.9c1.6-0.3,2.7-1.7,2.7-3.3
			c0-2.9-2.8-4.3-7.3-4.3H54.2l-2.6,4.8L49,0.4h-7.9l5.8,7.7l-3.3,4.2L39,0.4h-8.2L26.6,11L24.4,0.4h-7.4l-3.2,8.7l-3.2-8.7H3.4
			L0,16.6h6.7L7.8,7l3.3,9.6h5.6L20,7l1.1,9.6h10.4l0.6-2.1h5.5l0.6,2.1h10.1l3.3-5.1l3.3,5.1h17.2c6.2,0,7.7-2.3,7.7-4.4
			c0-0.1,0-0.2,0-0.3c1.5,2.7,4.9,5.2,10.3,5.2c7.5,0,11.3-5,11.3-8.6C101.3,4.3,97.1,0,90.1,0z M33.2,10.7l1.7-6l1.7,6H33.2z
			 M56.3,8.1l5-6.6v13L56.3,8.1z M70,4.5c1.4,0,2,0.5,2,1.1s-0.4,1.1-2,1.1h-2.1V4.5H70z M70.5,12.8h-2.6v-2.6h2.6
			c1.6,0,2.1,0.5,2.1,1.3S72.1,12.8,70.5,12.8L70.5,12.8z M90,11.9c-2.2,0-4.3-1.4-4.3-3.4s2-3.3,4.3-3.3s4.3,1.3,4.3,3.3
			S92.2,11.9,90,11.9L90,11.9z'
					/>
				</g>
			</g>
			<g>
				<path
					d='M4.1,20.1c1.2,0,2,0.1,2.7,0.8c0.8,0.7,1,1.7,1,2.5c0,0.4-0.1,1.5-0.8,2.3c-0.6,0.6-1.4,0.9-2.6,0.9H3.2v3.8
		h-2V20.1H4.1z M3.2,24.9H4c0.4,0,0.9,0,1.3-0.3c0.4-0.3,0.6-0.8,0.6-1.2c0-0.6-0.3-1-0.6-1.2c-0.4-0.3-0.9-0.3-1.4-0.3H3.2V24.9z'
				/>
				<path
					d='M9.1,23.7H11v1c0.3-0.6,0.7-0.8,0.8-0.9c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.6,0.1,0.8,0.2l-0.2,1.8
		c-0.2-0.2-0.5-0.2-0.9-0.2c-0.4,0-0.9,0.1-1.2,0.5c-0.4,0.4-0.4,1-0.4,1.5v3.2H9.1V23.7z'
				/>
				<path
					d='M20.6,24.5c0.7,0.6,1.1,1.5,1.1,2.6c0,1.1-0.5,2-1.1,2.6c-0.6,0.5-1.4,0.9-2.7,0.9c-1.3,0-2.1-0.4-2.7-0.9
		c-0.7-0.6-1.1-1.5-1.1-2.6c0-1.1,0.5-2,1.1-2.6c0.6-0.5,1.4-0.9,2.7-0.9C19.2,23.5,20.1,23.9,20.6,24.5z M19.8,27.1
		c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9S19.8,28.1,19.8,27.1z'
				/>
				<path
					d='M25.1,25.3v5.1h-1.9v-5.1h-0.7v-1.6h0.7v-1.8c0-0.6,0-1.7,0.9-2.4c0.4-0.3,1-0.6,1.6-0.6
		c0.4,0,0.8,0.1,1.1,0.2v1.7c-0.3-0.2-0.5-0.3-0.8-0.3c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.6-0.2,1.2v1.6h1.7v1.6H25.1z'
				/>
				<path
					d='M30.2,25.3v5.1h-1.9v-5.1h-0.7v-1.6h0.7v-1.8c0-0.6,0-1.7,0.9-2.4c0.4-0.3,1-0.6,1.6-0.6
		c0.4,0,0.8,0.1,1.1,0.2v1.7c-0.3-0.2-0.5-0.3-0.8-0.3c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.6-0.2,1.2v1.6h1.7v1.6H30.2z'
				/>
			</g>
		</svg>
	)
}

Logo.propTypes = {
	light: PropTypes.bool,
	className: PropTypes.string,
}

export default Logo
