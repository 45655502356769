import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, FormStep, Grid, Section, SelectGroup, useFormInput2 } from 'maxboapp-components'
import { isNotEmpty } from 'maxboapp-components/utils/validators'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'

const NO_PROJECT_ID = 'NO_PROJECT_ID'

export const StepCustomer = ({ stepNumber, nextStepNumber }) => {
	const t = useTranslator('erpapp.portal.offer.step-customer')
	const t0 = t.namespaced(() => '')

	const state = om.useState()
	const actions = om.useActions()

	const offer = state.offers.selectedOffer

	const form = {
		...useFormInput2({
			name: 'companyId',
			initialValue: offer.companyId,
			validators: [isNotEmpty],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setCompanyId,
			translate: t0.s,
		}),
		...useFormInput2({
			name: 'projectId',
			initialValue:
				offer.projectId === null && offer.step.reached > stepNumber
					? NO_PROJECT_ID
					: offer.projectId,
			validators: [],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setProjectId,
			translate: t0.s,
		}),
	}

	const invalid = Object.values(form).some((field) => !field.isValid)
	useEffect(() => {
		if (offer.step.active === 1) {
			actions.offers.setStep({ id: offer.id, step: 1, isInvalid: invalid })
		}
	}, [actions, offer.id, offer.step.active, invalid])

	if (!offer || !offer.step) {
		return null
	}

	const companyOptions = state.offers.companySearch.result.map((company) => ({
		id: company.id,
		title: company.firstname,
		subtitle: t0.s('erpapp.search.customer-number', [company.customAttributes?.erpCustomerNo]),
		onClick: () => {
			form.companyId.updateValue(company.id)
			actions.offers.setCompanyId(company.id)
		},
		selected: form.companyId.props.value === company.id,
	}))

	const projectOptions = state.offers.projectSearch.result
		.map((project) => ({
			id: project.id,
			title: project.name,
			subtitle: project.address,
			onClick: () => {
				form.projectId.updateValue(project.id)
				actions.offers.setProjectId(project.id)
			},
			selected: form.projectId.props.value === project.id,
		}))
		.filter((project) =>
			[project.id, project.title, project.subtitle].some((v) =>
				String(v)
					.toLowerCase()
					.includes(state.offers.projectSearch.query),
			),
		)

	const noProjectOption = {
		id: NO_PROJECT_ID,
		title: t.s('no-project.text'),
		icon: 'minusLarge',
		selected: form.projectId.props.value === NO_PROJECT_ID,
		onClick: () => {
			form.projectId.updateValue(NO_PROJECT_ID)
			actions.offers.setProjectId(null)
		},
	}

	return (
		<FormStep
			title={t.s('title')}
			value={
				state.offers.selectedOffer?.projectName
					? t.s('value', [
							state.offers.selectedOffer?.companyName ?? '',
							state.offers.selectedOffer?.projectName,
					  ])
					: t.s('value-without-project', [state.offers.selectedOffer?.companyName ?? ''])
			}
			step={stepNumber}
			activeStep={offer.step.active}
			setActiveStep={(step) => actions.offers.setStep({ id: offer.id, step })}
			reachedStep={offer.step.invalid ?? offer.step.reached}
		>
			<Section spacing='xlarge'>
				<Grid grid='2'>
					<SelectGroup
						errors={form.companyId.props.errors}
						showErrorsOnBlur={form.companyId.props.showErrorsOnBlur}
						forceShowErrors={form.companyId.props.forceShowErrors}
						inlineDeselect={
							form.companyId.props.value || form.companyId.props.value === 0
								? () =>
										form.companyId.props.onChange({
											target: { value: null },
											currentTarget: { value: null },
										})
								: undefined
						}
						label={t.s('customer.label')}
						trigger={t.s('customer.placeholder')}
						placeholder={t.s('customer.placeholder')}
						emptyResultLabel={t0.s('erpapp.search.no-items', [state.offers.companySearch.query])}
						options={companyOptions}
						inline
						search
						searchOnly={!form.companyId.props.value}
						isSearching={state.offers.companySearch.isSearching}
						query={state.offers.companySearch.isQueryValid ? state.offers.companySearch.query : ''}
						onChange={actions.offers.companySearch.setQuery}
					/>
				</Grid>

				{form.companyId.props.value && (
					<Grid grid='2'>
						<SelectGroup
							inlineDeselect={
								offer.projectId || offer.projectId === 0 || noProjectOption.selected
									? () => {
											form.projectId.updateValue(null)
											actions.offers.setProjectId(null)
									  }
									: undefined
							}
							label={t.s('project.label')}
							trigger={t.s('project.placeholder')}
							placeholder={t.s('project.placeholder')}
							emptyResultLabel={t0.s('erpapp.search.no-items', [state.offers.projectSearch.query])}
							options={form.companyId.props.value ? [noProjectOption, ...projectOptions] : []}
							inline
							search={Boolean(form.companyId.props.value)}
							isSearching={state.offers.projectSearch.isLoading}
							query={state.offers.projectSearch.query}
							onChange={actions.offers.projectSearch.setQuery}
							scrollLarge
						/>
					</Grid>
				)}
			</Section>

			<Section spacing='xlarge'>
				<Button
					primary
					large
					disabled={invalid}
					onClick={() => {
						if (offer.step.reached < nextStepNumber) {
							actions.offers.setTitle(
								`${offer.projectName || t.s('main-project.name')} - ${offer.companyName}`,
							)
						}
						actions.offers.setStep({ id: offer.id, step: nextStepNumber })
					}}
				>
					{t0.s('erpapp.offer.next')}
				</Button>
			</Section>
		</FormStep>
	)
}

StepCustomer.propTypes = {
	stepNumber: PropTypes.number.isRequired,
	nextStepNumber: PropTypes.number.isRequired,
}
