import './NotificationCount.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('notification-count')

const NotificationCount = ({ count }) => {
	return <span {...bem('')}>{count}</span>
}

NotificationCount.propTypes = {
	count: PropTypes.number,
}

export default NotificationCount
