"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toPrecision = exports.lenientParseFloat = void 0;
var lenientParseFloat = function lenientParseFloat(x) {
    return Number.parseFloat(String(x).replace(/,/g, '.').replace(/[\sA-Za-z]+/g, '')) || 0;
};
exports.lenientParseFloat = lenientParseFloat;
var toPrecision = function toPrecision(digitsOfPrecision) {
    var p = Math.pow(10, digitsOfPrecision);
    return function (x) {
        return Math.round(x * p) / p;
    };
};
exports.toPrecision = toPrecision;
