import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Dropdown, Grid, Icon, Section } from 'maxboapp-components'
import { translateStatus } from '@src/utils/offer/status'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { howLongAgo } from '@src/utils/time'
import { localizer, LOCALE_CODES } from '@src/utils/locale'
import './OfferHeader.scss'

const bem = new BEMHelper('offer-header')

const { convert } = localizer(LOCALE_CODES.NB).date

export const OfferHeader = ({ options }) => {
	const t = useTranslator('erpapp.portal.offer.header')

	const state = om.useState()
	const { statusCode, modifiedAt: modifiedAtUtc, ownerId } = state.offers.selectedOffer
	const owner = state.usersCache.local.db[ownerId]
	return (
		<Section spacing='xlarge'>
			<Grid align='between'>
				<span {...bem('')}>
					<Grid align='between'>
						<span {...bem('item')}>
							{t.s('offer-title.text', [translateStatus(statusCode, t.namespaced(() => '').s)])}
						</span>
						{modifiedAtUtc && (
							<span {...bem('item')}>
								{t.s('last-modified.text', [howLongAgo(convert(modifiedAtUtc))])}
							</span>
						)}
						{owner?.firstname && owner?.lastname && (
							<span {...bem('item')}>
								{t.s('owner.text', [`${owner.firstname} ${owner.lastname}`])}
							</span>
						)}
					</Grid>
				</span>
				{options?.length > 0 && <Dropdown icon={<Icon icon='options' />} options={options} />}
			</Grid>
		</Section>
	)
}

OfferHeader.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			onClick: PropTypes.func,
		}),
	),
}
