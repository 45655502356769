import { PATH } from '@src/constants/path'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { Button, Editorial, Grid, Preloader, Section } from 'maxboapp-components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { StatusOverview } from './StatusOverview'
import { OfferHeader } from '../OfferHeader/OfferHeader'
import { ChangeOwnerModal } from '../ChangeOwnerModal'
import { CopyOfferModal } from '../CopyOfferModal'

export const PublishedOffer = ({ location }) => {
	const [modalChangeOwner, setModalChangeOwner] = useState(false)
	const [modalCopyOffer, setModalCopyOffer] = useState(false)
	const tHeader = useTranslator('erpapp.portal.offer.header')
	const tH = tHeader.namespaced((ns) => `${ns}.options`)

	const t = useTranslator('erpapp.portal.offer.published')
	const tA = t.namespaced((ns) => `${ns}.adjust`)
	const tN = t.namespaced((ns) => `${ns}.notify-customer`)

	const offer = om.useState().offers.selectedOffer
	if (!offer) {
		return null
	}
	const publicPath = `${PATH.PORTAL.MY_OFFER}/${offer.publicId}`

	const options = [
		{
			label: tH.s('duplicate.label'),
			onClick: () => {
				setModalCopyOffer(true)
			},
		},
		{
			label: tH.s('change-owner.label'),
			onClick: () => setModalChangeOwner(true),
		},
	]

	return !offer.publicId ? (
		<Preloader full status={t.s('loading')} />
	) : (
		<>
			<OfferHeader options={options} />
			{modalChangeOwner && <ChangeOwnerModal onClose={() => setModalChangeOwner(false)} />}
			{modalCopyOffer && <CopyOfferModal onClose={() => setModalCopyOffer(false)} />}

			<Grid grid='2-1' padding>
				<Editorial>
					<h1>{t.s('title')}</h1>
					<Section spacing='xlarge'>
						<p>
							{offer.customerRefEmail // TODO: non-existing field at the moment
								? tN.s('notified.text', [offer.customerRefEmail])
								: tN.s('not-notified.text')}
							&nbsp;
							{tN.s('order-notice.text')}
						</p>
					</Section>

					<>
						<h2>{t.s('share-offer.title')}</h2>
						<Section spacing='xlarge'>
							<span className='preamble'>
								<a href={publicPath} rel='noreferrer' target='_blank'>
									{`${location.origin}${publicPath}`}
								</a>
							</span>
						</Section>
					</>

					{/* <Section spacing='xlarge'>
					<h2>{tO.s('title')}</h2>
					<DownloadList
						primary
						items={[
							{
								to: '/',
								// title: 'Tilbud til Bygg Smart AS: Familien Sørensen',
								title: tO.s('item.title', [offer.company?.firstname, offer.project?.name || '']),
								description: 'PDF 1.2 MB',
							},
						]}
					/>
				</Section> */}

					<Section spacing='xxlarge'>
						<h2>{tA.s('title')}</h2>
						<p>{tA.s('copy-notice.text')}</p>
						<Button small onClick={() => setModalCopyOffer(true)}>
							{tA.s('button.text')}
						</Button>
					</Section>
				</Editorial>
				<StatusOverview offer={offer} />
			</Grid>
		</>
	)
}

PublishedOffer.propTypes = {
	location: PropTypes.shape({ origin: PropTypes.string }),
}
