import useTranslations from '@src/hooks/useTranslations'
import { Button, FormInput, Note } from 'maxboapp-components'
import useFormInput from 'maxboapp-components/hooks/useFormInput'
import { isEmail, isNotEmpty } from 'maxboapp-components/utils/validators'
import PropTypes from 'prop-types'
import React from 'react'

export function LoginForm({ doSubmit, setIsSubmitting, isSubmitting = false, hasError = false }) {
	const t = useTranslations()

	const { username } = useFormInput({
		name: 'username',
		validators: [isNotEmpty, isEmail],
		options: { translationPrefix: 'erpapp' },
	})
	const { password } = useFormInput({
		name: 'password',
		validators: [isNotEmpty],
		options: { translationPrefix: 'erpapp' },
	})
	const formIsValid = [username, password].every((field) => field.isValid)

	const onSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setIsSubmitting(true)

		if (!formIsValid) {
			return
		}

		doSubmit({
			username: username.value,
			password: password.value,
		})
	}

	return (
		<form onSubmit={onSubmit}>
			<FormInput
				type='email'
				autoFocus={200} // eslint-disable-line jsx-a11y/no-autofocus
				name='username'
				label={t('erpapp.login.username')}
				onChange={username.onChange}
				errors={username.errors.map((err) => t(err))}
				forceShowErrors={isSubmitting}
				autoComplete='email'
			/>
			<FormInput
				name='password'
				type='password'
				label={t('erpapp.login.password')}
				onChange={password.onChange}
				errors={password.errors.map((err) => t(err))}
				forceShowErrors={isSubmitting}
				autoComplete='current-password'
			/>

			{hasError && (
				<Note type='subtle-error' inline hideIcon>
					{t('erpapp.login.error.general')}
				</Note>
			)}
			<Button large primary type='submit'>
				{t('erpapp.login')}
			</Button>
		</form>
	)
}

LoginForm.propTypes = {
	doSubmit: PropTypes.func.isRequired,
	setIsSubmitting: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool,
	hasError: PropTypes.bool,
}
