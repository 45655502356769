import React from 'react'
import { useTranslator } from '@src/hooks/useTranslations'

export const StatusOrderPlaced = ({ erpOrderRef }) => {
	const t = useTranslator('erpapp.portal.offer.published')

	return (
		<p>
			<span>
				{t.s('erp-order-id')}: {erpOrderRef}
			</span>
		</p>
	)
}
