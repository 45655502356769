import * as om from '@src/overmind'
import { lenientParseFloat } from '@src/utils/number'
import {
	CurrencyTableCell,
	InputTableCell,
	OverrideableTableCell,
	PercentageTableCell,
	PlainTableCell,
	ProductAdvancedTableCell,
} from 'maxboapp-components'
import { UnitConversionCell } from '@src/components/portal/offer/UnitConversion/UnitConversionCell'

export function getContentColumns({
	transProduct,
	actions,
	setModalRFQItemIds,
	setModalUnitConversionItemId,
}) {
	return [
		{
			id: 'productName',
			label: transProduct.s(),
			component: ProductAdvancedTableCell,
			style: {
				width: '200px',
			},
			getData: ({ rowId }) => {
				const item = om.useState().offerItems.local.db[rowId]
				return { name: item.productName, comment: item.comment }
			},
			onChange: (e, rowId) => {
				actions.offerItems.setComment({
					id: rowId,
					comment: e,
				})
			},
		},
		{
			id: 'sku',
			label: transProduct.s('item-number'),
			component: PlainTableCell,
			style: {
				width: '120px',
			},
			getData: ({ rowId }) => om.useState().offerItems.local.db[rowId].sku,
		},
		{
			id: 'productBrand',
			label: transProduct.s('brand'),
			component: PlainTableCell,
			style: {
				width: '100px',
			},
			getData: ({ rowId }) => om.useState().offerItems.local.db[rowId].productBrand,
		},
		{
			id: 'costPrice',
			label: transProduct.s('cost-price'),
			component: OverrideableTableCell,
			style: {
				textAlign: 'right',
				width: '170px',
			},
			getData: ({ rowId }) => {
				const item = om.useState().offerItems.local.db[rowId]
				return { current: item.costPrice, original: item.currentErpCostPrice }
			},
			onClick: (e, rowId) => setModalRFQItemIds([rowId]),
		},
		{
			// The financial term coverage (täckningsgrad)
			id: 'coverage',
			label: transProduct.s('coverage'),
			component: OverrideableTableCell,
			style: {
				textAlign: 'right',
				width: '170px',
			},
			format: (val) =>
				`${lenientParseFloat(val).toLocaleString('no-NO', { maximumFractionDigits: 2 })}%`,
			getData: ({ rowId }) => {
				const item = om.useState().offerItems.local.db[rowId]
				// TODO: move calculation to a offerItem model module?
				return {
					current: ((item.offeredPrice - item.costPrice) / item.offeredPrice) * 100,
					original:
						((item.currentErpOfferPrice - item.currentErpCostPrice) / item.currentErpOfferPrice) *
						100,
				}
			},
			onChange: (e, rowId) => {
				actions.offerItems.setCoverage({
					id: rowId,
					coverage: lenientParseFloat(e.target.value) / 100,
				})
			},
		},
		{
			id: 'offeredPrice',
			label: transProduct.s('offer-price'),
			component: OverrideableTableCell,
			getData: ({ rowId }) => {
				const item = om.useState().offerItems.local.db[rowId]
				return { current: item.offeredPrice, original: item.currentErpOfferPrice }
			},
			style: {
				textAlign: 'right',
				width: '170px',
			},
			onChange: (e, rowId) => {
				actions.offerItems.setOfferedPrice({
					id: rowId,
					offeredPrice: lenientParseFloat(e.target.value),
				})
			},
		},
		{
			id: 'qty',
			label: transProduct.s('quantity'),
			component: InputTableCell,
			style: {
				width: '100px',
				textAlign: 'right',
			},
			props: {
				rightAlign: true,
			},
			getData: ({ rowId }) => {
				const { qty } = om.useState().offerItems.local.db[rowId]
				return qty
			},
			onChange: (e, rowId) => {
				const validValue = e.target.value ? Number.parseInt(e.target.value, 10) : 0
				actions.offerItems.setQuantity({ id: rowId, qty: validValue })
			},
		},
		{
			id: 'unit',
			label: null,
			component: UnitConversionCell,
			style: {
				width: '60px',
			},
			getData: ({ rowId }) => om.useState().offerItems.local.db[rowId],
			onClick: (e, rowId) => setModalUnitConversionItemId(rowId),
		},
		{
			id: 'total',
			label: transProduct.s('total'),
			component: CurrencyTableCell,
			style: {
				textAlign: 'right',
				width: '100px',
			},
			getData: ({ rowId }) => {
				const { offeredPrice, currentErpOfferPrice, qty } = om.useState().offerItems.local.db[rowId]
				const price = offeredPrice ?? currentErpOfferPrice ?? 0
				return price * qty
			},
		},
	]
}

export function getFooterColumns({ transProduct }) {
	return [
		{
			id: 'ad',
			label: transProduct.s('ad'),
			component: CurrencyTableCell,
			style: {
				textAlign: 'right',
			},
			getData: () => om.useState().offers.selectedOfferPrices.earningsTotal,
		},
		{
			id: 'coverage',
			label: transProduct.s('coverage'),
			component: PercentageTableCell,
			style: {
				textAlign: 'right',
			},
			getData: () => om.useState().offers.selectedOfferPrices.coverage,
		},
		{
			id: 'total',
			label: transProduct.s('total'),
			component: CurrencyTableCell,
			style: {
				textAlign: 'right',
			},
			getData: () => om.useState().offers.selectedOfferPrices.priceTotalExcludingTax,
		},
	]
}
