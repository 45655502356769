import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Note, Container } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'

export const UploadedFeedback = ({ uploadedItems = 0, errors = [], warnings = [], onClose }) => {
	const transProductImport = useTranslator().namespaced(
		() => 'erpapp.portal.offer.product-import-modal',
	)
	const transProductItem = useTranslator().namespaced(
		() => 'erpapp.portal.offer.product-import-modal.item',
	)
	const [errorsCopied, setErrorsCopied] = useState(null)

	const copyErrorsToClipboard = async () => {
		try {
			navigator.clipboard.writeText(
				[
					...errors.map(
						(error) => `${error.identifier}: ` + transProductItem.s(`error.${error.message}`),
					),
					...warnings.map((warning) => `${warning.identifier}: ${warning.message}`),
				].join('\n'),
			)
			setErrorsCopied(true)
		} catch (_) {}
	}

	return (
		<>
			{errors.length > 0 && (
				<Note type='warning' inline>
					<div>
						{transProductImport.s('errors-message.text', [errors.length])}
						<ul>
							{errors.map((error, index) => (
								<li key={index}>
									{`${error.identifier}: `} {transProductItem.s(`error.${error.message}`)}
								</li>
							))}
						</ul>
					</div>
				</Note>
			)}
			{warnings.length > 0 && (
				<Note type='error' inline>
					<div>
						{transProductImport.s('unit-warning.text', [warnings.length])}
						<ul>
							{warnings.map((warning, index) => (
								<li key={index}>{`${warning.identifier}: ${warning.message}`}</li>
							))}
						</ul>
					</div>
				</Note>
			)}

			{[...errors, ...warnings].length > 0 && (
				<>
					<div>
						<Button small onClick={copyErrorsToClipboard} disabled={errorsCopied}>
							{errorsCopied
								? transProductImport.s('copy-errors.success.text')
								: transProductImport.s('copy-errors.text')}
						</Button>
					</div>
					<br />
				</>
			)}

			{uploadedItems > 0 && (
				<Note hideIcon={false}>
					{transProductImport.s('success-message.text', [uploadedItems])}
				</Note>
			)}
			<Container>
				<Button large primary onClick={onClose}>
					{transProductImport.s('close-button.text')}
				</Button>
			</Container>
		</>
	)
}
UploadedFeedback.propTypes = {
	onClose: PropTypes.func.isRequired,
	uploadedItems: PropTypes.number,
	errors: PropTypes.array,
	warnings: PropTypes.array,
}
