import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { Button, Checkbox, Modal2 as Modal, Preloader } from 'maxboapp-components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export function CopyOfferModal({ onClose }) {
	const t = useTranslator('erpapp.portal.offer.copy-offer-modal').s
	const [markAsExpired, setMarkAsExpired] = useState(false)
	const [loading, setLoading] = useState(false)

	const offer = om.useState().offers.selectedOffer

	const actions = om.useActions()

	const onSubmit = async () => {
		setLoading(true)
		await actions.offers.duplicate({
			expireOriginal: markAsExpired,
			title: `${offer.title} (${t('title-copy')})`,
		})
		onClose()
		setLoading(false)
	}

	return loading ? (
		<Preloader debounce full status={t('loading')} />
	) : (
		<Modal
			title={t('title')}
			tight
			handleClose={onClose}
			footer={
				<Button primary large onClick={onSubmit}>
					{t('footer.perform.text')}
				</Button>
			}
		>
			<p>{t('description.text')}</p>

			{!offer.erpOrderRef && (
				<Checkbox
					name='markAsExpired'
					label={t('checkbox.label')}
					checked={markAsExpired}
					onChange={(e) => setMarkAsExpired(e.target.value)}
				/>
			)}
		</Modal>
	)
}
CopyOfferModal.propTypes = {
	onClose: PropTypes.func,
}
