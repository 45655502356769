import {
	Button,
	DndContext,
	FormRow,
	Input,
	PlainTableCell,
	Preloader,
	TableLineItems,
	useDndMultiList,
} from 'maxboapp-components'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import BEMHelper from 'react-bem-helper'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { getDownloadUrl, getFileType } from '@src/utils/offer/attachment'
import { getSortOrder } from '@src/utils/offer'
import './Attachments.scss'

const bem = new BEMHelper('attachments')

export const Attachments = ({ offerId }) => {
	const transAttachment = useTranslator().namespaced(() => 'erpapp.portal.offer.attachment')
	const state = om.useState()
	const actions = om.useActions()
	const fileInputRef = useRef(null)
	const [isDraggingOver, setIsDraggingOver] = useState(false)

	const attachmentIds = state.attachments.byOffer[offerId] ?? []
	const offer = state.offers.local.db[offerId]
	const attachmentOrder = getSortOrder(offer.attachmentOrder, attachmentIds)

	const magentoToken = state.auth.token
	const { isFetching, isUploading } = state.attachments

	const onDeleteAttachment = (attachment) => {
		actions.attachments.deleteItem({
			offerId,
			attachmentId: attachment.id,
			token: magentoToken,
		})
		actions.offers.setAttachmentOrder(attachmentOrder.filter((id) => id !== attachment.id))
	}

	const uploadFiles = async (files) => {
		const ids = await Promise.all(files.map(uploadFile))
		actions.offers.setAttachmentOrder([...ids.filter(Boolean), ...attachmentOrder])
	}

	const uploadFile = async (file) => {
		const uploadedFile = await actions.attachments.upload({ offerId, file })
		return uploadedFile?.id
	}

	const resetFileInput = () => {
		if (fileInputRef?.current) {
			fileInputRef.current.value = ''
		}
	}

	const { selectHandler, onDragStart, onDragEnd, itemState, selection } = useDndMultiList({
		multiDndType: 'attachment',
		getList: () => ({ id: offerId, index: 0, itemOrder: attachmentOrder }),
		listOf: (itemId) => ({
			id: offerId,
			index: attachmentOrder.indexOf(itemId),
			itemOrder: attachmentOrder,
		}),
		listAt: (index) => ({
			id: attachmentOrder[index],
			index,
			itemOrder: attachmentOrder,
		}),
		setOrder: ({ order }) => {
			actions.offers.setAttachmentOrder(order)
		},
	})

	const deleteSelection = useCallback(
		// TODO: we should make a batch API in overmind
		async () =>
			Promise.all(
				selection.map((id) =>
					actions.attachments.deleteItem({
						offerId,
						attachmentId: id,
						token: magentoToken,
					}),
				),
			),
		[selection, actions, offerId, magentoToken],
	)

	useEffect(() => {
		function onKeyDown(event) {
			if (selection.length === 0) {
				return
			}

			if (event.key === 'Delete') {
				event.preventDefault()
				deleteSelection()
			}
		}
		document.addEventListener('keydown', onKeyDown)
		return () => {
			document.removeEventListener('keydown', onKeyDown)
		}
	}, [deleteSelection, selection.length])

	const attachmentColumns = [
		{
			id: 'name',
			label: transAttachment.s(''),
			component: PlainTableCell,
			style: {
				width: '45%',
			},
			getData: ({ rowId }) => {
				const attachment = om.useState().attachments.local.db[rowId]
				return <a href={getDownloadUrl(offerId, attachment.id, magentoToken)}>{attachment.name}</a>
			},
		},
		{
			id: 'description',
			label: transAttachment.s('description'),
			component: PlainTableCell,
			style: {
				width: '45%',
			},
			getData: ({ rowId }) => {
				const attachment = om.useState().attachments.local.db[rowId]
				return transAttachment.s('description.text', [
					getFileType(attachment.name).toUpperCase(),
					attachment.size,
				])
			},
		},
		{
			id: 'actions',
			label: '',
			component: PlainTableCell,
			style: {
				textAlign: 'right',
				width: '10%',
			},
			getData: ({ rowId }) => {
				const attachment = om.useState().attachments.local.db[rowId]
				return (
					<Button subtle small onClick={() => onDeleteAttachment(attachment)}>
						{transAttachment.s('delete.button.text')}
					</Button>
				)
			},
		},
	]

	let dragTimeout = null
	const attachmentDropProps = {
		onDrop: (e) => {
			e.preventDefault()
			setIsDraggingOver(false)
			uploadFiles(Array.from(e.dataTransfer.files))
			resetFileInput()
		},
		onDragOver: (e) => {
			e.preventDefault()
			clearTimeout(dragTimeout)
		},
		onDragEnter: (e) => {
			e.preventDefault()
			setIsDraggingOver(true)
			clearTimeout(dragTimeout)
		},
		onDragLeave: (e) => {
			e.preventDefault()
			dragTimeout = setTimeout(() => setIsDraggingOver(false), 200)
		},
	}

	return (
		<>
			<div {...bem('drop-area', { isDraggingOver })} {...attachmentDropProps}>
				<Input
					label={transAttachment.s('file-input.label')}
					disabled
					placeholder={
						isUploading
							? transAttachment.s('file-input.placeholder.uploading')
							: transAttachment.s('file-input.placeholder')
					}
					button={transAttachment.s('file-input.button.text')}
					buttonClick={() => !isUploading && fileInputRef?.current?.click()}
				/>
				<input
					type='file'
					multiple
					hidden
					ref={fileInputRef}
					onChange={(e) => {
						uploadFiles(Array.from(e.target.files))
						resetFileInput()
					}}
				/>
			</div>

			{isFetching ? (
				<Preloader xlarge status={transAttachment.s('fetching')} />
			) : (
				attachmentIds.length > 0 && (
					<FormRow marg>
						<DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
							<TableLineItems
								columns={attachmentColumns}
								droppableId={offerId}
								droppableType='attachment'
								items={attachmentOrder}
								emptyMessage={transAttachment.s('drag-and-drop')}
								reorder
								dndStates={itemState ? attachmentOrder.map(itemState) : []}
								selectHandler={selectHandler}
							/>
						</DndContext>
					</FormRow>
				)
			)}
		</>
	)
}

Attachments.propTypes = {
	offerId: PropTypes.number.isRequired,
}
