import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal2 as Modal, SelectGroup } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'

export function ChangeOwnerModal({ onClose }) {
	const t = useTranslator('erpapp.portal.offer.change-owner-modal')
	const t0 = t.namespaced(() => '')

	const actions = om.useActions()
	const state = om.useState()

	const ownerId = state.usersSelection.selectedUserId
	const onModalClose = () => {
		onClose()
		actions.usersSelection.search.setQuery('')
		actions.usersSelection.setSelectedUserId(null)
	}

	return (
		<Modal
			title={t.s('title')}
			tight
			handleClose={onModalClose}
			footer={
				<>
					<Button
						primary
						large
						disabled={!ownerId}
						onClick={() => {
							actions.offers.changeOwner(ownerId)
							onModalClose()
						}}
					>
						{t.s('footer.perform.text')}
					</Button>
					<Button large onClick={onModalClose}>
						{t.s('footer.cancel.text')}
					</Button>
				</>
			}
		>
			<p>{t.s('question.text')}</p>
			<SelectGroup
				label={t.s('search.title')}
				trigger={t.s('search.placeholder.text')}
				placeholder={t.s('search.placeholder.text')}
				emptyResultLabel={t0.s('erpapp.search.no-items', [state.usersSelection.search.query])}
				inlineDeselect={
					state.usersSelection.selectedUserId != null
						? () => actions.usersSelection.setSelectedUserId(null)
						: undefined
				}
				options={state.usersSelection.search.options(actions)}
				isSearching={state.usersSelection.search.isSearching}
				inline
				search
				searchOnly
				query={state.usersSelection.search.query}
				onChange={actions.usersSelection.search.setQuery}
			/>
		</Modal>
	)
}
ChangeOwnerModal.propTypes = {
	onClose: PropTypes.func,
}
