"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.localizer = exports.LOCALE_CODES = void 0;
var time_1 = require("./time");
exports.LOCALE_CODES = {
    NB: 'nb'
};
var localizer = function localizer(localeCode) {
    return {
        date: time_1.dateLocalizer(localeCode)
    };
};
exports.localizer = localizer;
