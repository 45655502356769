import * as om from '@src/overmind'
import React from 'react'
import { OFFER } from '@src/constants/offer'
import { Order } from './Order'
import { useTranslator } from '@src/hooks/useTranslations'
import { Button, Preloader } from 'maxboapp-components'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('status-overview')

export const StatusOrderNotPlaced = ({ offer, setPlacingOrder }) => {
	const actions = om.useActions()
	const state = om.useState()
	const t = useTranslator('erpapp.portal.offer.published.change-status')
	const isLoadingStore = state.stores.isLoading
	const store = state.stores.list[offer.storeId]
	const enablePlaceOrder = store?.routeTo === 'd365'
	const { statusCode } = offer

	return isLoadingStore ? (
		<div {...bem('preloader-wrapper')}>
			<Preloader debounce large />
		</div>
	) : (
		<>
			{store && statusCode === OFFER.STATUS_CODE.WON && (
				<Order disabled={!enablePlaceOrder} setPlacingOrder={setPlacingOrder} />
			)}
			<p>
				{statusCode === OFFER.STATUS_CODE.PENDING ? (
					<>
						<Button
							onClick={async () => {
								actions.offers.setStatusCode(OFFER.STATUS_CODE.WON)
							}}
						>
							{t.s('won.text')}
						</Button>
						&nbsp;
						<Button onClick={() => actions.offers.setStatusCode(OFFER.STATUS_CODE.LOST)}>
							{t.s('lost.text')}
						</Button>
					</>
				) : (
					<Button small onClick={() => actions.offers.setStatusCode(OFFER.STATUS_CODE.PENDING)}>
						{t.s('text')}
					</Button>
				)}
			</p>
			<p>{t.s('notice.text')}</p>
		</>
	)
}
