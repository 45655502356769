import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal2 as Modal } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'

export const PublishModal = ({ onClose }) => {
	const t = useTranslator('erpapp.portal.offer.publish-modal')
	const actions = om.useActions()
	return (
		<Modal
			title={t.s('title')}
			tight
			handleClose={() => onClose()}
			footer={
				<>
					<Button
						senary
						large
						onClick={() => {
							onClose()
							actions.offers.publish()
						}}
					>
						{t.s('publish.text')}
					</Button>
					<Button large onClick={() => onClose()}>
						{t.s('cancel.text')}
					</Button>
				</>
			}
		>
			<p>{t.s('confirm.text')}</p>
		</Modal>
	)
}
PublishModal.propTypes = {
	onClose: PropTypes.func.isRequired,
}
