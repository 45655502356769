import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal2 as Modal, Input, Note } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { UploadedFeedback } from './UploadedFeedback'

const VALID_FILE_TYPES = ['.csv']
const INITIAL_FEEDBACK = {
	uploadedItems: 0,
	errors: [],
	warnings: [],
	error: '',
	isUploading: false,
}
export const ProductImportModal = ({ onClose }) => {
	const transProductImport = useTranslator().namespaced(
		() => 'erpapp.portal.offer.product-import-modal',
	)
	const [selectedFile, setSelectedFile] = useState(null)
	const [feedback, setFeedback] = useState(INITIAL_FEEDBACK)
	const fileInputRef = useRef(null)
	const actions = om.useActions()
	const fileImported = feedback.uploadedItems > 0 || feedback.errors.length > 0

	const uploadFile = async () => {
		setFeedback((v) => ({ ...v, isUploading: true, error: '' }))
		try {
			const { errors, warnings, items } = await actions.offers.importProducts({
				file: selectedFile,
			})
			setFeedback((v) => ({
				...v,
				uploadedItems: items.length,
				errors,
				warnings,
				isUploading: true,
			}))
			setSelectedFile(null)
		} catch (e) {
			setFeedback((v) => ({
				...v,
				error: e?.response?.data?.message ?? e?.message,
				isUploading: false,
			}))
		}
		if (fileInputRef?.current) {
			fileInputRef.current.value = ''
		}
	}

	const closeModal = () => {
		setFeedback(INITIAL_FEEDBACK)
		onClose()
	}

	return (
		<Modal
			title={fileImported ? transProductImport.s('title-success') : transProductImport.s('title')}
			tight
			handleClose={closeModal}
		>
			{!fileImported && (
				<>
					<Input
						label={transProductImport.s('file-input.label')}
						disabled
						placeholder={
							feedback.isUploading
								? transProductImport.s('file-input.placeholder.uploading')
								: selectedFile?.name || transProductImport.s('file-input.placeholder')
						}
						button={transProductImport.s('file-input.button.text')}
						buttonClick={() => !feedback.isUploading && fileInputRef?.current?.click()}
					/>
					<input
						type='file'
						hidden
						ref={fileInputRef}
						onChange={async (e) => {
							let file = e?.target?.files?.[0]
							if (window.navigator.platform === 'Win32' && file.name.endsWith('.csv')) {
								// Windows incorrectly sets mime type to application/vnd.ms-excel by default (can be changed in RegEdit but we can't do that on client machines :')
								// We can't set file.type, so we create a new file.
								const contents = await file.arrayBuffer()
								file = new File([contents], file.name, {
									type: 'text/csv',
								})
							}
							setSelectedFile(file)
						}}
						accept={VALID_FILE_TYPES.join(',')}
					/>
					<p>{transProductImport.s('description.text')}</p>
					<p>{transProductImport.s('instructions.text')}</p>
				</>
			)}
			{feedback.error && (
				<Note type='subtle-error' inline hideIcon>
					{feedback.error}
				</Note>
			)}

			{fileImported && (
				<UploadedFeedback
					errors={feedback.errors}
					warnings={feedback.warnings}
					uploadedItems={feedback.uploadedItems}
					onClose={closeModal}
				/>
			)}

			{selectedFile && (
				<Button primary large onClick={uploadFile} disabled={feedback.isUploading}>
					{transProductImport.s('import-button.text')}
				</Button>
			)}
		</Modal>
	)
}

ProductImportModal.propTypes = {
	onClose: PropTypes.func.isRequired,
}
