import './StepContentsHeader.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Button, FormRow, Grid, Preloader } from 'maxboapp-components'
import { ProductAssortmentSearch } from '@src/components/portal/product/search'

const bem = new BEMHelper('contents-header')

export const StepContentsHeader = ({
	transStep,
	onClickImportProducts,
	onSelectProduct,
	onClickNewGroup,
	isAddingProduct,
}) => {
	return (
		<FormRow marg>
			<Grid>
				<div {...bem('search')}>
					<ProductAssortmentSearch onItemClick={onSelectProduct} />
					<span {...bem('spinner', { show: isAddingProduct })}>
						<Preloader debounce={isAddingProduct} medium />
					</span>
				</div>
				<div {...bem('buttons')}>
					<p>
						<Button onClick={onClickImportProducts}>{transStep.s('import-products')}</Button>{' '}
						<Button onClick={onClickNewGroup}>{transStep.s('new-group')}</Button>
					</p>
				</div>
			</Grid>
		</FormRow>
	)
}

StepContentsHeader.propTypes = {
	transStep: PropTypes.shape({ s: PropTypes.func }).isRequired,
	onClickImportProducts: PropTypes.func.isRequired,
	onSelectProduct: PropTypes.func.isRequired,
	onClickNewGroup: PropTypes.func.isRequired,
	isAddingProduct: PropTypes.bool,
}
