import './UnitConversionModal.scss'

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import { Button, NumberInput, Modal2 as Modal } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import { createBaseUnitFactor, createFromBaseDeriver, createToBaseDeriver } from './conversion'
import * as om from '@src/overmind'

const bem = new BEMHelper('unit-conversion-modal')

const getOtherUnit = (conversion, baseUnit) =>
	conversion.toUnit === baseUnit ? conversion.fromUnit : conversion.toUnit

const UnitConversionModal = ({ itemId, onClose = () => {} }) => {
	const actions = om.useActions()
	const offerItem = om.useState().offerItems.local.db[itemId]

	const [baseQuantity, setBaseQuantity] = useState(0)
	const [conversionValues, setConversionValues] = useState({})
	const t = useTranslator('erpapp.portal.offer-item.unit-conversion')

	const unitData = offerItem?.productUnitData ? JSON.parse(offerItem.productUnitData) : {}
	const { baseUnit, conversions = [] } = unitData

	const conversionUnits = conversions.filter(
		({ fromUnit, toUnit }) => fromUnit !== baseUnit || toUnit !== baseUnit,
	)

	const getBaseUnitFactor = (conversion) => {
		const changeFactorDeriver = createBaseUnitFactor(baseUnit, conversions)

		return changeFactorDeriver(getOtherUnit(conversion, baseUnit))
	}

	const convertFromBaseUnit = (conversion, qty) => {
		const baseDeriver = createFromBaseDeriver(getBaseUnitFactor(conversion))

		return baseDeriver(qty)
	}

	const getNewConversionValues = (quantity) =>
		conversionUnits.reduce(
			(acc, curr) => ({
				...acc,
				[getOtherUnit(curr, baseUnit)]: convertFromBaseUnit(curr, quantity),
			}),
			{},
		)

	const onSaveQuantity = () => {
		actions.offerItems.setQuantity({
			id: itemId,
			qty: baseQuantity,
		})
		onClose()
	}

	const onBaseChange = (newValue) => {
		const value = Number.parseInt(newValue, 10)
		const qty = Number.isFinite(value) ? value : ''
		const values = getNewConversionValues(qty)
		setConversionValues(values)
		setBaseQuantity(qty)
	}

	const onConversionChange = (conversion) => (newValue) => {
		const qty = newValue

		const deriver = createToBaseDeriver(getBaseUnitFactor(conversion))
		const newBaseQuantity = deriver(qty)
		const values = getNewConversionValues(newBaseQuantity)
		const newValues = { ...values, [getOtherUnit(conversion, baseUnit)]: qty }
		setBaseQuantity(Number.isFinite(newBaseQuantity) ? newBaseQuantity : 0)
		setConversionValues(newValues)
	}

	useEffect(() => {
		if (offerItem?.qty) {
			setBaseQuantity(offerItem.qty)
			setConversionValues(getNewConversionValues(offerItem.qty))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offerItem?.qty])

	if (!offerItem || Object.keys(conversionValues).length === 0) {
		return null
	}

	return (
		<div {...bem('')}>
			<Modal
				tight
				title={t.s('modal.title')}
				handleClose={() => onClose()}
				footer={
					<Button primary onClick={onSaveQuantity}>
						{t.s('save.text')}
					</Button>
				}
			>
				<span {...bem('subtitle')}>{`${offerItem.sku} - ${offerItem.productName}`}</span>
				<div {...bem('content')}>
					<strong {...bem('group_title')}>{t.s('base-unit.text')}</strong>
					<div {...bem('item', 'primary')}>
						<NumberInput
							value={baseQuantity}
							min={1}
							spaceless
							xsmall
							className={bem('item_input').className}
							onChange={onBaseChange}
						/>
						<span {...bem('unit')}>{baseUnit}</span>
					</div>

					<strong {...bem('group_title')}>{t.s('conversion.text')}</strong>

					{conversionUnits.map((u, i) => {
						const unit = getOtherUnit(u, baseUnit)
						return (
							<div key={unit} {...bem('item', i === 0 ? 'first' : '')}>
								<NumberInput
									value={conversionValues[unit]}
									min={1}
									spaceless
									xsmall
									className={bem('item_input').className}
									onChange={onConversionChange(u)}
								/>
								<span {...bem('unit')}>{unit}</span>
							</div>
						)
					})}
				</div>
			</Modal>
		</div>
	)
}

UnitConversionModal.propTypes = {
	itemId: PropTypes.number,
	onClose: PropTypes.func,
}

export default UnitConversionModal
