import React, { useState } from 'react'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { OFFER } from '@src/constants/offer'
import { StepCustomer } from './StepCustomer'
import { StepDetails } from './StepDetails'
import { StepContents } from './StepContents'
import { StepPresentation } from './StepPresentation'
import { StepPublish } from './StepPublish'
import { PublishModal } from './PublishModal'
import { RFQModal } from './RFQModal'
import { ProductImportModal } from './ProductImportModal'
import { ChangeOwnerModal } from '../ChangeOwnerModal'
import { DeleteDraftModal } from './DeleteDraftModal'
import { OfferHeader } from '../OfferHeader'
import { CopyOfferModal } from '../CopyOfferModal'
import { UnitConversionModal } from '../UnitConversion'

export function DraftOffer() {
	const t = useTranslator('erpapp.portal.offer.header.options')
	const { id, statusCode, step = {} } = om.useState().offers.selectedOffer

	const [modalPublish, setModalPublish] = useState(false)
	const [modalImport, setModalImport] = useState(false)
	const [modalDeleteDraft, setModalDeleteDraft] = useState(false)
	const [modalChangeOwner, setModalChangeOwner] = useState(false)
	const [modalRFQItemIds, setModalRFQItemIds] = useState([])
	const [modalCopyOffer, setModalCopyOffer] = useState(false)
	const [modalUnitConversionItemId, setModalUnitConversionItemId] = useState()

	const isDbOffer = statusCode === OFFER.STATUS_CODE.DRAFT && id !== OFFER.TAB.NEW
	const hasBeenSaved = id !== OFFER.TAB.NEW

	const options = [
		hasBeenSaved && {
			label: t.s('duplicate.label'),
			onClick: () => {
				setModalCopyOffer(true)
			},
		},
		hasBeenSaved && {
			label: t.s('change-owner.label'),
			onClick: () => setModalChangeOwner(true),
		},
		isDbOffer && {
			label: t.s('delete.label'),
			onClick: () => {
				setModalDeleteDraft(true)
			},
		},
	].filter(Boolean)

	return (
		<>
			<OfferHeader options={options} />
			<div>
				<StepCustomer stepNumber={1} nextStepNumber={2} />
				{step.reached >= 2 && <StepDetails stepNumber={2} nextStepNumber={3} />}
				{step.reached >= 3 && (
					<StepContents
						{...{
							setModalImport,
							setModalRFQItemIds,
							setModalUnitConversionItemId,
						}}
						stepNumber={3}
						nextStepNumber={4}
					/>
				)}
				{step.reached >= 4 && <StepPresentation stepNumber={4} nextStepNumber={5} />}
				{step.reached >= 5 && <StepPublish {...{ setModalPublish }} stepNumber={5} />}
			</div>
			{modalPublish && <PublishModal onClose={() => setModalPublish(false)} />}
			{modalRFQItemIds.length > 0 && (
				<RFQModal itemIds={modalRFQItemIds} onClose={() => setModalRFQItemIds([])} />
			)}
			{modalImport && <ProductImportModal onClose={() => setModalImport(false)} />}
			{modalDeleteDraft && <DeleteDraftModal onClose={() => setModalDeleteDraft(false)} />}
			{modalChangeOwner && <ChangeOwnerModal onClose={() => setModalChangeOwner(false)} />}
			{modalCopyOffer && <CopyOfferModal onClose={() => setModalCopyOffer(false)} />}
			{modalUnitConversionItemId && (
				<UnitConversionModal
					itemId={modalUnitConversionItemId}
					onClose={() => setModalUnitConversionItemId(null)}
				/>
			)}
		</>
	)
}
