import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Container,
	ContextMenu,
	DndContext,
	DndList,
	Editorial,
	Preloader,
	Section,
	TableFooter,
	TableGroup,
	useDndMultiList,
	arrayMove,
} from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { getSortOrder } from '@src/utils/offer'
import { StepContentsHeader } from './StepContentsHeader'
import { OfferContentsFormStep } from './ContentsFormStep'
import { getContentColumns, getFooterColumns } from './columns'
import { GroupList } from './GroupList'
import { MemoizedTableLineItems } from './MemoizedTableLineItems'

const ORDER_TYPE = {
	GROUP: 'group',
	ITEM: 'item',
}

export const StepContents = ({
	setModalImport,
	setModalRFQItemIds,
	stepNumber,
	nextStepNumber,
	setModalUnitConversionItemId,
}) => {
	const state = om.useState()
	const actions = om.useActions()
	const t = useTranslator()
	const transProduct = t.namespaced(() => 'erpapp.portal.offer.product')
	const transStep = t.namespaced(() => 'erpapp.portal.offer.step-contents')
	const transGroupOption = t.namespaced(() => 'erpapp.portal.offer.step-contents.group-option')
	const [focusId, setFocusId] = useState()
	const [loadingData, setLoadingData] = useState(false)
	const [contextMenu, setContextMenu] = useState({
		show: false,
		xPos: '0px',
		yPos: '0px',
		rowId: null,
	})

	const offer = state.offers.selectedOffer

	const groupOrder = getSortOrder(offer.groupOrder, state.offerGroups.byOffer[offer.id] ?? [])
	const offerGroups = groupOrder.map((id) => state.offerGroups.local.db[id])
	const itemOrderByGroup = offerGroups.reduce(
		(o, { id, itemOrder }) => ({
			...o,
			[id]: getSortOrder(itemOrder, state.offerItems.byGroup[id] ?? []),
		}),
		{},
	)

	const groups = state.offerGroups.byOffer[offer.id] ?? undefined
	const items = state.offerItems.byOffer[offer.id] ?? undefined

	const setOrder = ({ type, id, order }) => {
		switch (type) {
			case ORDER_TYPE.GROUP: {
				actions.offers.setGroupOrder(order)
				return
			}
			case ORDER_TYPE.ITEM: {
				actions.offerGroups.setItemOrder({ id, itemOrder: order })
				for (const itemId of order) {
					actions.offerItems.setGroupId({ id: itemId, groupId: id })
				}
				return
			}
			default:
				throw new Error(`Missing setOrder handler for dnd type ${type}`)
		}
	}

	const { selectHandler, onDragStart, onDragEnd, itemState, selection } = useDndMultiList({
		multiDndType: ORDER_TYPE.ITEM,
		getList: (id, type) => {
			if (type === ORDER_TYPE.GROUP) {
				return { id, itemOrder: groupOrder }
			}
			const index = groupOrder.indexOf(id)
			const itemOrder = itemOrderByGroup[id]
			return { id, index, itemOrder }
		},
		listOf: (itemId) => {
			const id = state.offerItems.local.db[itemId].groupId
			const index = groupOrder.indexOf(id)
			const itemOrder = itemOrderByGroup[id]
			return { id, index, itemOrder }
		},
		listAt: (index) => {
			const id = groupOrder[index]
			const itemOrder = itemOrderByGroup[id]
			return { id, index, itemOrder }
		},
		setOrder,
	})

	const contextMenuHandler = (e, rowId) => {
		setContextMenu({
			xPos: `${e.pageX}px`,
			yPos: `${e.pageY}px`,
			show: true,
			rowId,
		})
		e.preventDefault()
	}

	const deleteSelection = useCallback(
		// TODO: we should make a batch API in overmind
		async () => Promise.all(selection.map((id) => actions.offerItems.deleteItem(id))),
		[selection, actions],
	)

	useEffect(() => {
		setLoadingData(
			state.offers.loadingSelectedIdData && (groups === undefined || items === undefined),
		)
	}, [state.offers.loadingSelectedIdData, groups, items])

	useEffect(() => {
		function onKeyDown(event) {
			if (selection.length === 0) {
				return
			}

			if (event.key === 'Delete') {
				event.preventDefault()
				deleteSelection()
			}
		}
		document.addEventListener('keydown', onKeyDown)
		return () => {
			document.removeEventListener('keydown', onKeyDown)
		}
	}, [deleteSelection, selection.length])

	const createNewGroup = async (event) => {
		event.preventDefault()
		setLoadingData(true)
		const created = await actions.offerGroups.create({ offerId: offer.id })
		setLoadingData(false)
		if (!created) {
			return
		}
		setFocusId(created.id)
		actions.offers.setGroupOrder([created.id, ...groupOrder])

		// HACK: Removing focusId to not autofocus after creation is made
		// TODO: The need for this indicates that there's either
		// 1. a bug in TableGroup that autoFocus is not only running on mount.
		// 2. an unexpected remount of the TableGroup happening.
		setTimeout(() => setFocusId(), 100)
	}

	const addProductToGroup = async (product) => {
		let groupId = groupOrder[0]
		if (!groupId) {
			const created = await actions.offerGroups.create({ offerId: offer.id })

			if (!created) {
				// TODO show error message
				return
			}

			groupId = created.id
		}
		const offerItem = await actions.offerItems.create({
			groupId,
			sku: product.sku,
			offerId: offer.id,
		})
		if (!offerItem) {
			return
		}
		const itemOrder = [offerItem.id, ...(itemOrderByGroup[groupId] || [])]
		actions.offerGroups.setItemOrder({ id: groupId, itemOrder })
	}

	return (
		<>
			<OfferContentsFormStep transStep={transStep} stepNumber={stepNumber}>
				<StepContentsHeader
					transStep={transStep}
					onClickImportProducts={() => setModalImport(true)}
					onSelectProduct={addProductToGroup}
					onClickNewGroup={createNewGroup}
					isAddingProduct={state.offerItems.isCreating}
				/>
				{loadingData ? <Preloader debounce large overlay /> : null}
				<DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
					<DndList
						droppableId={offer.id}
						droppableType={ORDER_TYPE.GROUP}
						listComponent={GroupList}
						items={groupOrder}
						renderItem={({ provided, snapshot, item: id, index: i, collection: a }) => {
							const items = [...itemOrderByGroup[id]]
							return (
								<TableGroup
									draggable={{ provided, snapshot }}
									title={om.useState().offerGroups.local.db[id].name ?? ''}
									setTitle={(event) => {
										actions.offerGroups.setName({ id, name: event.target.value })
									}}
									options={[
										i > 0 && {
											label: transGroupOption.s('move-up.label'),
											onClick: () => {
												const ix = groupOrder.indexOf(id)
												setOrder({
													type: ORDER_TYPE.GROUP,
													order: arrayMove(groupOrder, ix, ix - 1),
												})
											},
										},
										i < a.length - 1 && {
											label: transGroupOption.s('move-down.label'),
											onClick: () => {
												const ix = groupOrder.indexOf(id)
												setOrder({
													type: ORDER_TYPE.GROUP,
													order: arrayMove(groupOrder, ix, ix + 1),
												})
											},
										},
										// NOTE: see story MS-52 in MVP2
										// {
										// 	label: transGroupOption.s('duplicate.label'),
										// 	onClick: () => {},
										// },
										{
											label: transGroupOption.s('delete.label'),
											onClick: () => {
												actions.offerGroups.deleteItem(id)
												setOrder({
													type: ORDER_TYPE.GROUP,
													order: groupOrder.filter((gId) => id !== gId),
												})
											},
										},
									].filter(Boolean)}
									autoFocus={id === focusId} // eslint-disable-line jsx-a11y/no-autofocus
								>
									<MemoizedTableLineItems
										droppableId={id}
										droppableType={ORDER_TYPE.ITEM}
										items={items}
										columns={getContentColumns({
											transProduct,
											actions,
											setModalRFQItemIds,
											setModalUnitConversionItemId,
										})}
										reorder
										selectHandler={selectHandler}
										contextMenuHandler={contextMenuHandler}
										dndStates={itemState ? items.map(itemState) : []}
										memoize
										// batchFunctions={
										// 	{
										// 		id: 'move-group',
										// 		label: transProduct.s('table.move-group'),
										// 		onClick: () => {},
										// 	},
										// 	{
										// 		id: 'new-group',
										// 		label: transProduct.s('table.new-group'),
										// 		onClick: () => {},
										// 	},
										// 	{
										// 		id: 'rfq',
										// 		label: transProduct.s('table.rfq'),
										// 		onClick: setModalRFQItemIds,
										// 	},
										// 	{
										// 		id: 'delete',
										// 		label: transProduct.s('table.delete'),
										// 		onClick: () => {},
										// 	},
										// ]}
									/>
								</TableGroup>
							)
						}}
					/>
				</DndContext>

				<TableFooter columns={getFooterColumns({ transProduct })} />

				<Section spacing='xlarge'>
					<Button
						primary
						large
						onClick={() => actions.offers.setStep({ id: offer.id, step: nextStepNumber })}
					>
						{t.s('erpapp.offer.next')}
					</Button>
				</Section>

				<Container size='tight'>
					<Editorial>
						<p>{transStep.s('information-text')}</p>
					</Editorial>
				</Container>
			</OfferContentsFormStep>
			{contextMenu.show && (
				<ContextMenu
					xPos={contextMenu.xPos}
					yPos={contextMenu.yPos}
					items={[
						{
							label: transStep.s('item.reset'),
							onClick: () => {
								actions.offerItems.reset(contextMenu.rowId)
								setContextMenu((v) => ({ ...v, show: false, rowId: null }))
							},
						},
						{
							label: transStep.s('item.delete'),
							onClick: () => {
								actions.offerItems.deleteItem(contextMenu.rowId)
								setContextMenu((v) => ({ ...v, show: false, rowId: null }))
							},
						},
					]}
					onClose={() => setContextMenu((v) => ({ ...v, show: false, rowId: null }))}
				/>
			)}
		</>
	)
}

StepContents.propTypes = {
	setModalImport: PropTypes.func.isRequired,
	setModalRFQItemIds: PropTypes.func.isRequired,
	stepNumber: PropTypes.number.isRequired,
	nextStepNumber: PropTypes.number.isRequired,
}
