import { Helmet } from 'react-helmet'
import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Tab, Tabs } from 'maxboapp-components'
import { OFFER } from '@src/constants/offer'
import { PATH } from '@src/constants/path'
import useTranslations from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { ellipsis } from '@src/utils/string'
import { translateStatus } from '@src/utils/offer'
import { DraftOffer } from './DraftOffer/DraftOffer'
import { AllOffersTab } from './AllOffersTab'
import { OfferStatus } from './OfferStatus'
import { PublishedOffer } from './PublishedOffer'
import { NonOwnerOffer } from './NonOwnerOffer'

export const OfferTabs = ({ location }) => {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const { selectedId } = state.offers
	const { userId } = state.auth
	const offers = state.tabs.openedOffers
		.filter((offerId) => state.offers.local.db[offerId])
		.map((offerId) => state.offers.local.db[offerId])

	return (
		<Tabs active={selectedId ?? OFFER.TAB.SEARCH} onChange={actions.offers.navigateTo} closable>
			<Tab id={OFFER.TAB.SEARCH} title={t('erpapp.all-offers.title')}>
				<AllOffersTab id={OFFER.TAB.SEARCH} />
			</Tab>
			{offers.map((offer) => {
				const { id, title, ownerId } = offer
				const statusCode = offer.statusCode ?? OFFER.STATUS_CODE.DRAFT

				const tabProps = {
					key: id,
					id,
					title: ellipsis(16)(title),
					onClose: () => {
						actions.tabs.removeFromOpenedOffers(id)
						if (id === selectedId) {
							navigate(PATH.PORTAL.OFFER)
						}
					},
					status: <OfferStatus code={statusCode} tooltip={translateStatus(statusCode, t)} />,
				}

				if (id !== selectedId) {
					return <Tab {...tabProps} />
				}

				let component
				if (ownerId !== userId) {
					component = <NonOwnerOffer />
				} else {
					const View = statusCode === OFFER.STATUS_CODE.DRAFT ? DraftOffer : PublishedOffer
					component = <View location={location} />
				}

				return (
					<Tab {...tabProps}>
						<Helmet>
							<title>{`Salg - ${title}`}</title>
						</Helmet>
						{component}
					</Tab>
				)
			})}
		</Tabs>
	)
}

OfferTabs.propTypes = {
	location: PropTypes.shape({}),
}
