import * as om from '@src/overmind'
import { formatPrice, FormStep } from 'maxboapp-components'
import PropTypes from 'prop-types'
import React from 'react'

// NOTE: this is separated in order for StepOfferContents to not have to observe `selectedOfferPrices`
export const OfferContentsFormStep = ({ children, transStep, stepNumber }) => {
	const state = om.useState()
	const actions = om.useActions()

	const offer = state.offers.selectedOffer
	const { priceTotalExcludingTax } = state.offers.selectedOfferPrices

	return (
		<FormStep
			title={transStep.s('title')}
			value={transStep.s('title-value', [formatPrice(priceTotalExcludingTax)])}
			step={stepNumber}
			activeStep={offer.step.active}
			setActiveStep={(step) => actions.offers.setStep({ id: offer.id, step })}
			reachedStep={offer.step.invalid ?? offer.step.reached}
		>
			{children}
		</FormStep>
	)
}
OfferContentsFormStep.propTypes = {
	children: PropTypes.node,
	transStep: PropTypes.shape({ s: PropTypes.func.isRequired }).isRequired,
	stepNumber: PropTypes.number.isRequired,
}
