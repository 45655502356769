import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal2 as Modal } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { OFFER } from '@src/constants/offer'

export function DeleteDraftModal({ onClose }) {
	const t = useTranslator('erpapp.portal.offer.delete-modal')
	const actions = om.useActions()
	const offer = om.useState().offers.selectedOffer
	return (
		<Modal
			title={t.s('title')}
			tight
			handleClose={() => onClose()}
			footer={
				<>
					<Button
						primary
						large
						block
						onClick={() => {
							actions.offers.deleteItem(offer.id)
							onClose()
							actions.offers.navigateTo(OFFER.TAB.SEARCH)
						}}
					>
						{t.s('footer.delete.text')}
					</Button>
					<Button
						large
						block
						onClick={() => {
							onClose()
						}}
					>
						{t.s('footer.cancel.text')}
					</Button>
				</>
			}
		>
			<p>{t.s('question.text')}</p>
		</Modal>
	)
}
DeleteDraftModal.propTypes = {
	onClose: PropTypes.func,
}
