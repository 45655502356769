import React, { useState } from 'react'
import { useTranslator } from '@src/hooks/useTranslations'
import { CopyOfferModal } from '../CopyOfferModal'
import { OfferHeader } from '../OfferHeader'
import { ChangeOwnerModal } from '../ChangeOwnerModal'

export const NonOwnerOfferHeader = () => {
	const t = useTranslator('erpapp.portal.offer.header.options')

	const [modalCopyOffer, setModalCopyOffer] = useState(false)
	const [modalChangeOwner, setModalChangeOwner] = useState(false)

	const options = [
		{
			label: t.s('duplicate.label'),
			onClick: () => {
				setModalCopyOffer(true)
			},
		},
		{
			label: t.s('change-owner.label'),
			onClick: () => setModalChangeOwner(true),
		},
	]

	return (
		<>
			<OfferHeader options={options} />
			{modalCopyOffer && <CopyOfferModal onClose={() => setModalCopyOffer(false)} />}
			{modalChangeOwner && <ChangeOwnerModal onClose={() => setModalChangeOwner(false)} />}
		</>
	)
}
