import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Container,
	Editorial,
	FormInput,
	FormStep,
	Grid,
	Icon,
	Section,
	useFormInput2,
} from 'maxboapp-components'
import { isNotEmpty } from 'maxboapp-components/utils/validators'
import { offerPreviewPath } from '@src/constants/path'
import useTranslations from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { Attachments } from './Attachments'

export const StepPresentation = ({ stepNumber, nextStepNumber }) => {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const offer = state.offers.selectedOffer

	const form = {
		...useFormInput2({
			name: 'title',
			initialValue: offer.title,
			validators: [isNotEmpty],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setTitle,
			translate: t,
		}),
		...useFormInput2({
			name: 'description',
			initialValue: offer.description ?? '',
			validators: [],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setDescription,
			translate: t,
		}),
	}

	const invalid = Object.values(form).some((field) => !field.isValid)
	useEffect(() => {
		if (offer.step.active === 3) {
			actions.offers.setStep({ id: offer.id, step: 3, isInvalid: invalid })
		}
	}, [actions, offer.id, offer.step.active, invalid])

	return (
		<FormStep
			title={t('erpapp.offer.presentation')}
			value={offer.step.active === 4 ? t('erpapp.offer.presentation') : offer.title}
			step={stepNumber}
			activeStep={offer.step.active}
			setActiveStep={(step) => actions.offers.setStep({ id: offer.id, step })}
			reachedStep={offer.step.invalid ?? offer.step.reached}
		>
			<Section spacing='xxlarge'>
				<Grid grid='2'>
					<FormInput label={t('erpapp.offer.title')} {...form.title.props} />
				</Grid>

				<FormInput
					label={t('erpapp.offer.description')}
					type='textarea'
					{...form.description.props}
				/>
				<Attachments offerId={offer.id} />
			</Section>

			<Section spacing='xlarge'>
				<Button
					primary
					large
					disabled={invalid}
					onClick={async () => {
						actions.offers.setStep({ id: offer.id, step: nextStepNumber })
					}}
				>
					{t('erpapp.offer.next')}
				</Button>{' '}
				<Button
					large
					iconRight
					onClick={() => {
						const newWindow = window.open()
						newWindow.opener = null
						newWindow.location = offerPreviewPath(offer.id)
					}}
				>
					{t('erpapp.offer.preview')}
					<Icon icon='download' />
				</Button>
			</Section>

			<Container size='tight'>
				<Editorial>
					<p>{t('erpapp.offer.info-to-seller')}</p>
				</Editorial>
			</Container>
		</FormStep>
	)
}

StepPresentation.propTypes = {
	stepNumber: PropTypes.number.isRequired,
	nextStepNumber: PropTypes.number.isRequired,
}
