import React from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Container,
	Datepicker,
	Editorial,
	FormStep,
	Icon,
	Section,
} from 'maxboapp-components'
import { OFFER } from '@src/constants/offer'
import * as om from '@src/overmind'
import { useTranslator } from '@src/hooks/useTranslations'
import { capitalizeFirstLetter } from '@src/utils/string'
import { offerPreviewPath } from '@src/constants/path'
import { localizer, LOCALE_CODES } from '@src/utils/locale'

export const StepPublish = ({ setModalPublish, stepNumber }) => {
	const t = useTranslator('erpapp.portal.offer.step-publish')
	const state = om.useState()
	const actions = om.useActions()
	const offer = state.offers.selectedOffer
	const { convert, now } = localizer(LOCALE_CODES.NB).date

	const selectedDate = offer.expiryDate ? convert(offer.expiryDate) : now().add(1, 'month')

	return (
		<FormStep
			title={t.s(`title.${state === OFFER.STATUS_CODE.DRAFT ? '' : 'non-'}draft`)}
			value={t.s('value', [capitalizeFirstLetter(offer.statusCode ?? '')])}
			step={stepNumber}
			activeStep={offer.step.active}
			setActiveStep={(step) => actions.offers.setStep({ id: offer.id, step })}
			reachedStep={offer.step.invalid ?? offer.step.reached}
		>
			<Section spacing='xlarge'>
				<Datepicker
					label={t.s('expiry-date.label')}
					minDate={new Date()}
					selectedDate={selectedDate.toDate()}
					onChange={(date) => {
						actions.offers.setExpiryDate(convert(date).format())
					}}
				/>
			</Section>
			<p>
				<Button
					primary
					large
					onClick={() => {
						if (!offer.expiryDate) {
							actions.offers.setExpiryDate(convert(selectedDate.toDate()).format())
						}
						setModalPublish(true)
					}}
				>
					{t.s('ready-to-publish.text')}
				</Button>{' '}
				<Button
					large
					iconRight
					onClick={() => {
						const newWindow = window.open()
						newWindow.opener = null
						newWindow.location = offerPreviewPath(offer.id)
					}}
				>
					{t.s('preview.text')}
					<Icon icon='download' />
				</Button>
			</p>
			<Container size='tight'>
				<Editorial>
					<p>{t.s('publish-notice.text')}</p>
				</Editorial>
			</Container>
		</FormStep>
	)
}
StepPublish.propTypes = {
	setModalPublish: PropTypes.func.isRequired,
	stepNumber: PropTypes.number.isRequired,
}
