import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Note, Preloader } from 'maxboapp-components'
import * as om from '@src/overmind'
import { useTranslator } from '@src/hooks/useTranslations'
import { SummaryDetails, SummaryHeader } from '@src/components/portal/offer/summary'
import { isPublicOfferId } from '@src/utils/offer'
import BEMHelper from 'react-bem-helper'
import './Print.scss'

const bem = new BEMHelper('offer-summary')

export const OfferSummary = ({ offerId }) => {
	const t = useTranslator()
	const tH = t.namespaced(() => 'erpapp.portal.header')
	const tS = t.namespaced(() => 'erpapp.portal.summary')
	const state = om.useState()
	const actions = om.useActions()
	const offer = state.offers.selectedOffer
	const loadingData = !offer || state.offers.loadingSelectedIdData
	const publicOffer = offer && isPublicOfferId(offerId)

	useEffect(() => {
		// Set state.offers.selectedOffer
		actions.offers.onNavigate(offerId)
	}, [offerId, actions.offers])

	const message = offer
		? publicOffer
			? `${tS.s('note.published-part1.text', [
					offer.salesRefName,
			  ])} ${tS.s('note.published-part2.text', [offer.salesRefPhone])}`
			: tS.s('note.preview.text')
		: null

	return loadingData ? (
		<Preloader noAnimation full status={t.s('erpapp.portal.offer.loading')} />
	) : (
		<div {...bem('')}>
			<SummaryHeader>
				<Button small onClick={() => window.print()}>
					{tH.s('print.text')}
				</Button>
			</SummaryHeader>
			<Note type='cta' align='center' marginBottom={false} hideIcon={false}>
				<span>{message}</span>
			</Note>
			<SummaryDetails />
		</div>
	)
}

OfferSummary.propTypes = {
	offerId: PropTypes.string,
}
