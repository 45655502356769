import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	FormStep,
	Grid,
	Preloader,
	Section,
	FormInput,
	useFormInput2,
} from 'maxboapp-components'
import { isTelephone, isNotEmpty } from 'maxboapp-components/utils/validators'
import { OFFER } from '@src/constants/offer'
import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'

export const StepDetails = ({ stepNumber, nextStepNumber }) => {
	const t = useTranslator('erpapp.portal.offer.step-offer-details')
	const tR = t.namespaced((ns) => `${ns}.reference`)
	const t0 = t.namespaced(() => '')

	const state = om.useState()
	const actions = om.useActions()

	const [creatingOffer, setCreatingOffer] = useState(false)

	const offer = state.offers.selectedOffer

	const form = {
		...useFormInput2({
			name: 'title',
			initialValue: offer.title,
			validators: [isNotEmpty],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setTitle,
			translate: t0.s,
		}),
		...useFormInput2({
			name: 'customerRefName',
			initialValue: offer.customerRefName,
			validators: [isNotEmpty],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setCustomerRefName,
			translate: t0.s,
		}),
		...useFormInput2({
			name: 'customerRefPhone',
			initialValue: offer.customerRefPhone,
			validators: [isTelephone],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setCustomerRefPhone,
			translate: t0.s,
		}),
		...useFormInput2({
			name: 'salesRefName',
			initialValue: offer.salesRefName,
			validators: [isNotEmpty],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setSalesRefName,
			translate: t0.s,
		}),
		...useFormInput2({
			name: 'salesRefPhone',
			initialValue: offer.salesRefPhone,
			validators: [isTelephone],
			options: { translationPrefix: 'erpapp' },
			setValue: actions.offers.setSalesRefPhone,
			translate: t0.s,
		}),
	}

	const invalid = Object.values(form).some((field) => !field.isValid)
	useEffect(() => {
		if (offer.step.active === 2) {
			actions.offers.setStep({ id: offer.id, step: 2, isInvalid: invalid })
		}
	}, [actions, offer.id, offer.step.active, invalid])

	return (
		<FormStep
			title={t.s('title')}
			value={t.s('value', [state.offers.selectedOffer?.title])}
			step={stepNumber}
			activeStep={offer.step.active}
			setActiveStep={(step) => actions.offers.setStep({ id: offer.id, step })}
			reachedStep={offer.step.invalid ?? offer.step.reached}
		>
			{creatingOffer ? <Preloader debounce large overlay /> : null}
			<Section spacing='xlarge' title={t.s('offer-title.label')}>
				<Grid grid='2'>
					<FormInput placeholder={t.s('offer-title.placeholder')} {...form.title.props} />
				</Grid>
			</Section>

			<Grid grid='2'>
				<Section spacing='xlarge' title={tR.s('customer.title')}>
					<Grid grid='2'>
						<FormInput label={tR.s('full-name.label')} {...form.customerRefName.props} />
						<FormInput label={tR.s('phone.label')} {...form.customerRefPhone.props} />
					</Grid>
				</Section>
				<Section spacing='xlarge' title={tR.s('maxbo.title')}>
					<Grid grid='2'>
						<FormInput label={tR.s('full-name.label')} {...form.salesRefName.props} />
						<FormInput label={tR.s('phone.label')} {...form.salesRefPhone.props} />
					</Grid>
				</Section>
			</Grid>

			<Section spacing='xlarge'>
				<Button
					primary
					large
					disabled={invalid}
					onClick={async () => {
						if (offer.id === OFFER.TAB.NEW) {
							setCreatingOffer(true)
							await actions.offers.submitNew()
							setCreatingOffer(false)
						} else {
							actions.offers.setStep({ id: offer.id, step: nextStepNumber })
						}
					}}
				>
					{t0.s('erpapp.offer.next')}
				</Button>
			</Section>
		</FormStep>
	)
}

StepDetails.propTypes = {
	stepNumber: PropTypes.number.isRequired,
	nextStepNumber: PropTypes.number.isRequired,
}
