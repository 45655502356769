import React from 'react'
import PropTypes from 'prop-types'
import { OfferSummary } from '@src/components/portal/offer/summary'

const PublicOffer = ({ publicOfferId }) => {
	return <OfferSummary offerId={publicOfferId} />
}

PublicOffer.propTypes = {
	publicOfferId: PropTypes.string,
}

export default PublicOffer
