import { Header } from '@src/components/portal/header'
import { PATH, PREV_PATH_KEY } from '@src/constants/path'
import * as om from '@src/overmind'
import { getItem, removeItem } from '@src/utils/storage'
import { navigate } from 'gatsby'
import { Container, Preloader } from 'maxboapp-components'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'
import { LoginForm } from './LoginForm'
import './LoginPage.scss'

const bem = new BEMHelper('login-page')

export function LoginPage({ uri }) {
	const actions = om.useActions()
	const state = om.useState()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const redirect = () =>
		pipe(
			getItem(sessionStorage, PREV_PATH_KEY),
			O.getOrElse(() => PATH.PORTAL.OFFER),
			(path) => {
				removeItem(sessionStorage, PREV_PATH_KEY)
				navigate(path)
			},
		)

	useEffect(() => {
		if (state.auth.isLoggedIn) {
			redirect()
		}
	}, [state.auth.isLoggedIn])

	const onSubmit = ({ username, password }) => {
		actions.auth.login({ username, password }).then((success) => {
			setIsSubmitting(false)
			if (success) {
				redirect()
			}
		})
	}

	return (
		<>
			<Header full uri={uri} />
			<Container size='tight' {...bem('')}>
				{state.auth.isLoading && (
					<div {...bem('backdrop')}>
						<div {...bem('preload-wrapper')}>
							<Preloader large />
						</div>
					</div>
				)}
				<LoginForm
					doSubmit={onSubmit}
					setIsSubmitting={setIsSubmitting}
					isSubmitting={isSubmitting}
					hasError={Boolean(state.auth.error)}
				/>
			</Container>
		</>
	)
}

LoginPage.propTypes = {
	uri: PropTypes.string,
}
