import './AllOffersTab.scss'
import { Helmet } from 'react-helmet'
import React, { useEffect, useMemo } from 'react'
import { Button, FormRow, Grid, Icon, Preloader, Section, SelectGroup } from 'maxboapp-components'
import { OFFER } from '@src/constants/offer'
import useTranslations from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import {
	getCompanyOfferSubtitle,
	getOfferSubtitle,
	mapToOfferOption,
} from '@src/overmind/dashboard/util'
import BEMHelper from 'react-bem-helper'
import { Search } from '../search'

const bem = new BEMHelper('all-offers-tab')

export const AllOffersTab = React.memo(function AllOffersTab() {
	const state = om.useState()

	return (
		<Grid grid='2-1' moreGutter>
			<>
				<Helmet>
					<title>Salg - Alle Tilbud</title>
				</Helmet>
				<FormRow marg>
					<Search />
				</FormRow>
				<FormRow marg>
					{state.dashboard.selectedCustomer === null ? (
						<MyLatestOffersLoader />
					) : (
						<CompanyOffersLoader />
					)}
				</FormRow>
			</>
		</Grid>
	)
})

const MyLatestOffersLoader = React.memo(function MyLatestOffersLoader() {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const { isLoadingLatestOffers } = state.dashboard

	useEffect(() => {
		actions.dashboard.loadLatestOffers()
	}, [actions.dashboard])

	return isLoadingLatestOffers ? (
		<div {...bem('loader')}>
			<Preloader xlarge status={t('erpapp.portal.your-last-offers.loading')} />
		</div>
	) : (
		<MyLatestOffers />
	)
})

const MyLatestOffers = React.memo(function MyLatestOffers() {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const { selectedId } = state.offers

	return (
		<SelectGroup
			label={t('erpapp.your-last-offers')}
			options={state.dashboard.latestOffers
				.filter((id) => state.offers.local.db[id])
				.map((id) =>
					mapToOfferOption(t, selectedId, actions.offers.navigateTo)(getOfferSubtitle)(
						state.offers.local.db[id],
					),
				)}
			inline
			emptyResultLabel={t('erpapp.search.no-result')}
		/>
	)
})

const CompanyOffersLoader = React.memo(function CompanyOffersLoader() {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const loadingCompanyOffers =
		state.dashboard.loadingCompanyOffers &&
		(!state.dashboard.companyOffers || state.dashboard.companyOffers.length <= 0)

	return (
		<>
			<Section spacing='xlarge'>
				<Button
					primary
					onClick={() =>
						actions.offers.createEmpty({
							companyId: state.dashboard.selectedCustomer.id,
						})
					}
				>
					<Icon icon='plusLarge' />
					{t('erpapp.portal.header.new-offer.text')}
				</Button>
			</Section>
			{loadingCompanyOffers ? (
				<Preloader xlarge status={t('erpapp.portal.company-offers.loading')} />
			) : (
				<CompanyOffers />
			)}
		</>
	)
})

const CompanyOffers = React.memo(function CompanyOffers() {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const { selectedId } = state.offers

	const companyOfferOptions = useMemo(
		() =>
			state.dashboard.companyOffers
				.filter((id) => state.offers.local.db[id])
				.map((id) =>
					mapToOfferOption(t, selectedId, actions.offers.navigateTo)(getCompanyOfferSubtitle)(
						state.offers.local.db[id],
					),
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state.dashboard.companyOffers],
	)

	return (
		<>
			<SelectGroup
				label={t('erpapp.search.active-offers')}
				emptyResultLabel=''
				options={companyOfferOptions.filter((option) =>
					[OFFER.STATUS_CODE.DRAFT, OFFER.STATUS_CODE.PENDING].includes(option.status.code),
				)}
				inline
			/>
			<SelectGroup
				label={t('erpapp.search.won-offers')}
				emptyResultLabel=''
				options={companyOfferOptions.filter(
					(option) => option.status.code === OFFER.STATUS_CODE.WON,
				)}
				inline
			/>
			<SelectGroup
				label={t('erpapp.search.lost-offers')}
				emptyResultLabel=''
				options={companyOfferOptions.filter(
					(option) => option.status.code === OFFER.STATUS_CODE.LOST,
				)}
				inline
			/>
		</>
	)
})
