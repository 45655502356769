import React from 'react'
import { navigate } from 'gatsby'
import { SelectGroup } from 'maxboapp-components'
import { json } from 'overmind'
import { PATH } from '@src/constants/path'
import useTranslations from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { aggregateSearch } from '@src/overmind/dashboard/aggregate'
import { getOfferSubtitle, mapToOfferOption } from '@src/overmind/dashboard/util'

const genOptions = (customers, t, offers, state, actions) => {
	return aggregateSearch(
		Object.entries(json(customers)).reduce(
			(acc, [uniqueId, customer]) => ({
				...acc,
				[uniqueId]: {
					uniqueId,
					id: customer.id,
					title: customer.firstname,
					subtitle: t('erpapp.search.customer-number', [customer?.customAttributes?.erpCustomerNo]),
				},
			}),
			{},
		),
		Object.entries(json(offers)).reduce(
			(acc, [uniqueId, offer]) => ({
				...acc,
				[uniqueId]: {
					uniqueId,
					...mapToOfferOption(t, state.offers.selectedId)(getOfferSubtitle)(offer),
				},
			}),
			{},
		),
	).map(({ uniqueId, ...result }) => ({
		...result,
		id: uniqueId,
		selected: state.dashboard.selectedId === uniqueId,
		onClick: () => {
			if (uniqueId.includes('offer')) {
				navigate(`${PATH.PORTAL.OFFER}/${result.id}`)
				return
			}
			actions.dashboard.setSelected(uniqueId)
			actions.dashboard.loadCompanyOffers(result.id).then(() => {})
		},
	}))
}

const Search = () => {
	const t = useTranslations()
	const state = om.useState()
	const actions = om.useActions()

	const { customers = {}, offers = {} } = state.dashboard.searchResult
	const searchResultOptions = genOptions(customers, t, offers, state, actions)

	return (
		<SelectGroup
			label={t('erpapp.search.offer')}
			emptyResultLabel={t('erpapp.search.no-result')}
			trigger={t('erpapp.search.description')}
			placeholder={t('erpapp.search.description')}
			inlineDeselect={
				state.dashboard.selectedId !== null ? () => actions.dashboard.setSelected(null) : undefined
			}
			options={searchResultOptions}
			inline
			search
			searchOnly
			isSearching={state.dashboard.isSearching}
			query={state.dashboard.isQueryValid ? state.dashboard.query : ''}
			onChange={actions.dashboard.setQuery}
		/>
	)
}

export default Search
