import React from 'react'
import { Note, Preloader } from 'maxboapp-components'
import { useTranslator } from '@src/hooks/useTranslations'
import { SummaryDetails } from '../summary'
import { NonOwnerOfferHeader } from './NonOwnerOfferHeader'
import * as om from '@src/overmind'

export const NonOwnerOffer = () => {
	const t = useTranslator('')

	const isLoading = om.useState().offers.loadingSelectedIdData

	if (isLoading) {
		return <Preloader noAnimation xlarge debounce status={t.s('erpapp.portal.offer.loading')} />
	}

	return (
		<>
			<NonOwnerOfferHeader />
			<Note marginBottom={false} hideIcon={false}>
				<span>{t.s('erpapp.portal.offer.not-owner.text')}</span>
			</Note>
			<SummaryDetails />
		</>
	)
}
