import { useTranslator } from '@src/hooks/useTranslations'
import * as om from '@src/overmind'
import { lenientParseFloat } from '@src/utils/number'
import {
	CurrencyTableCell,
	InputTableCell,
	PlainTableCell,
	TableLineItems,
} from 'maxboapp-components'
import PropTypes from 'prop-types'
import React from 'react'

export const RFQItemsTable = ({ offerItems }) => {
	const actions = om.useActions()
	const t = useTranslator()
	const transRFQ = t.namespaced(() => 'erpapp.portal.offer-item.rfq.modal').s

	const columns = [
		{
			id: 'product',
			label: transRFQ('product.label'),
			component: PlainTableCell,
			style: {
				width: '50%',
			},
			getData: ({ rowId }) => offerItems[rowId].productName,
		},
		{
			id: 'lineitem',
			label: transRFQ('item-number.label'),
			component: PlainTableCell,
			style: {
				width: '100px',
			},
			getData: ({ rowId }) => offerItems[rowId].sku,
		},
		{
			id: 'costPrice',
			label: transRFQ('standard-cost-price.label'),
			component: CurrencyTableCell,
			style: {
				textAlign: 'right',
				width: '130px',
			},
			getData: ({ rowId }) => offerItems[rowId].currentErpCostPrice,
		},
		{
			id: 'newCostPrice',
			label: transRFQ('new-cost-price.label'),
			component: InputTableCell,
			style: {
				width: '100px',
				textAlign: 'right',
			},
			props: {
				rightAlign: true,
			},
			onChange: (e, row) => {
				actions.offerItems.setTempCostPrice({
					id: row,
					costPrice: lenientParseFloat(e.target.value),
				})
			},
			getData: ({ rowId }) => offerItems[rowId].tempCostPrice,
		},
	]

	return <TableLineItems marg columns={columns} items={Object.keys(offerItems)} />
}
RFQItemsTable.propTypes = {
	offerItems: PropTypes.objectOf(
		PropTypes.shape({
			productName: PropTypes.string,
			productImagePath: PropTypes.string,
			sku: PropTypes.string,
			erpCostPriceAtPublish: PropTypes.float,
			costPrice: PropTypes.float,
			tempCostPrice: PropTypes.float,
		}),
	).isRequired,
}
