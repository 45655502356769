import PropTypes from 'prop-types'
import React from 'react'
import BEMHelper from 'react-bem-helper'
import './GroupList.scss'

const bem = new BEMHelper('group-list')

export function GroupList({
	provided: { innerRef, droppableProps },
	snapshot: { isDraggingOver },
	children,
}) {
	return (
		<div ref={innerRef} {...droppableProps} {...bem('', { isDraggingOver })}>
			{children}
		</div>
	)
}
GroupList.propTypes = {
	provided: PropTypes.shape({
		innerRef: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.shape({ current: PropTypes.elementType }),
		]),
		droppableProps: PropTypes.shape({}),
	}),
	snapshot: PropTypes.shape({
		isDraggingOver: PropTypes.bool,
	}),
	children: PropTypes.node,
}
